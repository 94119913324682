<template>
	<transition name="fade">
		<div v-if="visible" :style="style" class="tooltip-content">
			{{ text }}
		</div>
	</transition>
</template>

<script>
	export default {
		name: 'ToolTip',
		props: {
			text: {
				type: String,
				required: true,
			},
			visible: {
				type: Boolean,
				required: true,
			},
			style: {
				type: Object,
				required: true,
			},
		},
	};
</script>

<style scoped>
	.tooltip-content {
		position: absolute;
		background-color: black;
		color: white;
		padding: 5px;
		border-radius: 4px;
		white-space: nowrap;
		z-index: 1000;
		opacity: 0.75;
		transform: translate(-50%, -100%);
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.3s;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
</style>
