<template>
	<fieldset class="rounded">
		<slot></slot>
	</fieldset>
</template>

<script>
	export default {};
</script>

<style scoped>
	fieldset + fieldset {
		margin-top: 1.5rem;
	}
</style>
