import { createApp, h, ref } from 'vue';
import Tooltip from '../components/ToolTip.vue';

export default {
	beforeMount(el, binding) {
		const tooltipEl = document.createElement('div');
		const visible = ref(false);
		const style = ref({});

		// eslint-disable-next-line no-unused-vars
		el.addEventListener('mouseenter', (event) => {
			visible.value = true;
			const rect = el.getBoundingClientRect();
			style.value = {
				top: `${rect.top - 10}px`,
				left: `${rect.left + rect.width / 2}px`,
			};
			document.body.appendChild(tooltipEl);
		});

		el.addEventListener('mouseleave', () => {
			visible.value = false;
			setTimeout(() => {
				document.body.removeChild(tooltipEl);
			}, 300); // Match the duration of the transition
		});

		const app = createApp({
			render() {
				return h(Tooltip, {
					text: binding.value,
					visible: visible.value,
					style: style.value,
				});
			},
		});
		app.mount(tooltipEl);
	},
};
