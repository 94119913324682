<template>
	<div class="d-flex">
		<div class="flex-shrink-0">
			<slot name="image"></slot>
		</div>
		<div class="flex-grow-1 ms-2">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style></style>
