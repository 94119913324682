<template>
	<div id="app">
		<main id="main" :class="{ 'has-menu': hasSideNav }">
			<AppHeader
				v-if="hasHeader"
				:show-visit-store-button="hasSideNav"
				@display-sidebar="displaySideBar"
			/>
			<AppSidebar
				v-if="hasSideNav"
				:display-mobile-side-bar="displayMobileSideBar"
				@display-sidebar="displaySideBar"
			/>
			<section
				id="content"
				:class="{
					'has-header': hasHeader,
					'is-theme-editor': isThemeEditor,
					'disable-scroll': displayMobileSideBar,
				}"
			>
				<ElToast />
				<router-view :key="$route.name"></router-view>
			</section>
		</main>

		<ElModalLogin v-if="modalLoginVisible" />
		<ElSpriteSvg />
	</div>
</template>

<script>
	import AppHeader from '@/components/layout/AppHeader.vue';
	import AppSidebar from '@/components/layout/AppSidebar.vue';
	import ElSpriteSvg from '@/components/ui/ElSpriteSvg.vue';
	import ElModalLogin from '@/components/ui/ElModalLogin.vue';

	import { useAuthStore } from '@/stores/auth';

	export default {
		name: 'App',
		setup() {
			const authStore = useAuthStore();
			return { authStore };
		},
		components: {
			ElSpriteSvg,
			AppSidebar,
			AppHeader,
			ElModalLogin,
		},

		metaInfo: {
			// all titles will be injected into this template
			titleTemplate: '%s | Lexmodo Admin',
		},

		data() {
			return {
				messages: this.$messages,
				displayMobileSideBar: false,
			};
		},
		computed: {
			modalLoginVisible() {
				return this.authStore.loginModalVisible;
			},
			hasHeader() {
				return (
					!this.$_.isEmpty(this.$route.name) &&
					!this.$route.meta.hideHeader
				);
			},
			hasSideNav() {
				return (
					!this.$_.isEmpty(this.$route.name) &&
					!this.$route.meta.hideSideNav
				);
			},
			isThemeEditor() {
				return this.$route.name === 'templates_configuration';
			},
		},

		methods: {
			displaySideBar(statusDisplay) {
				this.displayMobileSideBar = statusDisplay;
			},
		},
	};
</script>

<style>
	.disable-scroll {
		overflow: hidden;
		height: 100%;
	}
</style>
