<template>
	<div>
		<legend class="fs-5 fw-bold mb-0" :class="innerClass">
			<slot></slot>
		</legend>
	</div>
</template>

<script>
	export default {
		props: {
			innerClass: {
				type: String,
				default: '',
			},
		},
	};
</script>

<style scoped>
	div {
		margin-bottom: 0.75rem;
	}

	legend {
		float: none;
		width: auto;
	}
</style>
