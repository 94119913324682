<template>
	<div class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
		<span class="visually-hidden">Loading...</span>
	</div>
</template>

<script>
	export default {};
</script>

<style></style>
