<template>
	<button type="button" class="btn">
		<slot></slot>
	</button>
</template>
<style scoped lang="scss">
	.btn {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
	}
</style>
