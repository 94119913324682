import { ref } from 'vue';
const toastMessages = ref([]);

export default {
	install: (app) => {
		app.config.globalProperties.$toastMessages = toastMessages.value;

		app.config.globalProperties.$toast = function () {
			let show = (options) => {
				if (!options.placement) options.placement = 'top-center';
				if (!options.duration) options.duration = 5000;

				let notification = {
					type: options.type,
					message: options.message,
					placement: options.placement,
					duration: options.duration,
				};

				toastMessages.value.push(notification);
			};

			let clear = () => {
				toastMessages.value.splice(0);
			};

			let hasSuccessMessage = () => {
				toastMessages.value.forEach((message) => {
					if (message.type === 'success') return true;
				});

				return false;
			};

			return { show, clear, hasSuccessMessage };
		};

		app.config.globalProperties.$showToastSuccess = function (
			textMessage,
			duration
		) {
			this.$toast().show({
				type: 'success',
				message: textMessage,
				duration: duration,
			});
		};

		app.config.globalProperties.$showToastInfo = function (
			textMessage,
			duration
		) {
			this.$toast().show({
				type: 'info',
				message: textMessage,
				duration: duration,
			});
		};

		app.config.globalProperties.$showToastWarning = function (
			textMessage,
			duration
		) {
			this.$toast().show({
				type: 'warning',
				message: textMessage,
				duration: duration,
			});
		};

		app.config.globalProperties.$showToastError = function (
			textMessage,
			duration
		) {
			this.$toast().show({
				type: 'danger',
				message: textMessage,
				duration: duration,
			});
		};
	},
};
