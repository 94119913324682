import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import cloneDeep from 'lodash/cloneDeep';
import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import snakeCase from 'lodash/snakeCase';
import difference from 'lodash/difference';
import differenceWith from 'lodash/differenceWith';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import has from 'lodash/has';
import keys from 'lodash/keys';
import last from 'lodash/last';
import map from 'lodash/map';
import range from 'lodash/range';
import set from 'lodash/set';
import size from 'lodash/size';
import slice from 'lodash/slice';
import startsWith from 'lodash/startsWith';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import upperFirst from 'lodash/upperFirst';

export default {
	install: (app) => {
		app.config.globalProperties.$_ = {
			isEmpty,
			isEqual,
			isNull,
			isObject,
			isUndefined,
			cloneDeep,
			camelCase,
			capitalize,
			snakeCase,
			difference,
			differenceWith,
			filter,
			forEach,
			get,
			has,
			keys,
			last,
			map,
			range,
			set,
			size,
			slice,
			startsWith,
			union,
			uniq,
			uniqBy,
			upperFirst,
		};
	},
};
