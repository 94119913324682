import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useAccountsStore } from '@/stores/accounts';
import { useSettingsStore } from '@/stores/settings';

import { grpc } from '@improbable-eng/grpc-web';
import {
	onMessageResponseAsync,
	onEndResponseAsync,
} from '@/functions/handleGrpcResponse';

import { Client } from 'lex-proto/clients_pb';
import { Clients } from 'lex-proto/clients_pb_service';

// import {
// 	Configuration as KetoConfiguration,
// 	ReadApi as KetoReadApi,
// } from '@ory/keto-client';

import {
	getCookie,
	setCookie,
	parseJson,
	parseStorageJson,
	getMainDomainNameFromUrl,
	SETTINGS_KEY,
} from '@/functions/common';

import { Empty } from 'google-protobuf/google/protobuf/empty_pb';

import isNull from 'lodash/isEmpty';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

// import axios from 'axios';

const transportWithCredentials = grpc.CrossBrowserHttpTransport({
	withCredentials: true,
});
grpc.setDefaultTransport(transportWithCredentials);

// const axiosInstance = axios.create({
// 	withCredentials: true,
// });

// const ketoApi = new KetoReadApi(
// 	new KetoConfiguration({
// 		basePath: import.meta.env.VITE_KETO_PUBLIC_API,
// 	}),
// 	import.meta.env.VITE_KETO_PUBLIC_API,
// 	axiosInstance
// );

// export const SELECTED_STORE_KEY = 'selectedStore';
export const SELECTED_STORE_KEY = 'lex_store';
export const ACCESS_TOKEN_KEY = 'oauthAccessToken';

export const useClientsStore = defineStore('clients', {
	state: () => ({
		selectedStore: parseJson(getCookie(SELECTED_STORE_KEY)),
		adminStores: [],
		onboardingCompleted: true, //FIXME: default false and update after checking API
	}),
	actions: {
		setSelectedStore(storeInfo) {
			//clear previous store settings forcing load settings of new selected store
			localStorage.removeItem(SETTINGS_KEY);
			this.selectedStore = storeInfo;
			// localStorage.setItem(SELECTED_STORE_KEY, JSON.stringify(storeInfo));
			setCookie(SELECTED_STORE_KEY, JSON.stringify(storeInfo));
			setCookie(
				import.meta.env.VITE_IS_DEV == 'true'
					? 'lexmodo_client_id_dev'
					: 'lexmodo_client_id',
				storeInfo.clientId,
				{ domain: 'lexmodo.com' }
			);
		},
		clearSelectedStore() {
			this.selectedStore = null;
			localStorage.removeItem(SELECTED_STORE_KEY);
			setCookie(SELECTED_STORE_KEY, '{}', { expires: 0 });
			localStorage.removeItem(ACCESS_TOKEN_KEY);
			localStorage.removeItem(SETTINGS_KEY);
		},
		setOnboardingCompleted() {
			this.onboardingCompleted = true;
		},
		getAdminStores(adminId) {
			if (!isEmpty(this.adminStores))
				return Promise.resolve(this.adminStores);

			return new Promise((resolve, reject) => {
				useAccountsStore()
					.getUserStores(adminId)
					.then(
						({ clientIdsList }) => {
							if (isUndefined(clientIdsList)) resolve([]);
							else {
								let cloneList = [...clientIdsList];
								this.adminStores = cloneList;
								resolve(cloneList);
							}
						},
						(err) => reject(err)
					);
			});
		},
		createStore(storeData) {
			return new Promise((resolve, reject) => {
				const clientRequest = new Client();
				clientRequest.setStoreName(storeData.storeName);
				clientRequest.setStoreWebsite(storeData.storeWebsite);
				clientRequest.setStoreLogo(storeData.storeLogo);

				grpc.invoke(Clients.CreateClient, {
					request: clientRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': this.getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		getStore(storeId) {
			return new Promise((resolve, reject) => {
				const clientRequest = new Client();
				clientRequest.setClientId(storeId);

				grpc.invoke(Clients.GetClientInfo, {
					request: clientRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': storeId,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		getAdminToken() {
			return new Promise((resolve, reject) => {
				let token = parseStorageJson(ACCESS_TOKEN_KEY);
				if (
					!isEmpty(token) &&
					!isEmpty(token.accessToken) &&
					Math.floor(Date.now() / 1000) < token.accessTokenExpiry - 60
				) {
					return resolve(token);
				}
				this.refreshAdminToken().then(
					(successResp) => {
						if (isEmpty(successResp.client.oauthKeys.accessToken))
							return reject('Got empty token from API');
						localStorage.setItem(
							ACCESS_TOKEN_KEY,
							JSON.stringify(successResp.client.oauthKeys)
						);
						resolve(successResp.client.oauthKeys);
					},
					(errorResp) => reject(errorResp)
				);
			});
		},
		refreshAdminToken() {
			return new Promise((resolve, reject) => {
				const clientRequest = new Empty();

				grpc.invoke(Clients.GetAdminToken, {
					request: clientRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': this.getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		async persistStoreSettings() {
			let storeSettings = parseStorageJson(SETTINGS_KEY);
			if (
				isNull(storeSettings) ||
				new Date().getTime() > storeSettings.expiry
			) {
				try {
					const settingsStore = useSettingsStore();
					let settingsResponse =
						await settingsStore.getGeneralSettings();
					settingsResponse.generalSettings.expiry =
						new Date().getTime() + 30 * 60 * 1000; //30min in mil secs
					localStorage.setItem(
						SETTINGS_KEY,
						JSON.stringify(settingsResponse.generalSettings)
					);
				} catch (errorGetSettings) {
					console.log(
						'Error loading store settings: ',
						errorGetSettings
					);
				}
			}
		},
		invalidateStoreSettingsCache() {
			localStorage.removeItem(SETTINGS_KEY);
			this.persistStoreSettings();
		},
		updateSelectedStore(store) {
			this.setSelectedStore(store);
			this.persistStoreSettings();
		},
		async matchSubdomainWithSelectedStore(waitDnsPropagation = false) {
			if (
				!this.isAdminWithStoreSubdomain ||
				window.location.pathname.indexOf('/oauth/') === 0
			)
				return;
			if (!useAuthStore().isAuthenticated || !this.getSelectedStore) {
				if (window.location.host !== import.meta.env.VITE_MAIN_DOMAIN)
					window.location.href = `https://${
						import.meta.env.VITE_MAIN_DOMAIN
					}${window.location.pathname}${window.location.search}`;
			} else {
				let storeAdminUrl = `https://${this.getSelectedStoreDomain}.lexmodo.com`;
				let currentUrl = `${window.location.protocol}//${window.location.host}`;
				if (currentUrl !== storeAdminUrl) {
					if (waitDnsPropagation)
						await this.checkDnsPropagationBeforeRedirect(
							storeAdminUrl
						);
					else this.redirectToStoreAdminUrl(storeAdminUrl);
				} else await this.persistStoreSettings();
			}
		},
		async checkDnsPropagationBeforeRedirect(storeAdminUrl, tryCount = 1) {
			let { status } = await fetch(storeAdminUrl, {
				method: 'HEAD',
				mode: 'cors',
				cache: 'no-cache',
			});
			// wait up to 5 seconds for dns propagation on store creation
			if (status === 200 || status === 204 || tryCount === 5)
				this.redirectToStoreAdminUrl(storeAdminUrl);
			else
				setTimeout(
					() =>
						this.checkDnsPropagationBeforeRedirect(
							storeAdminUrl,
							++tryCount
						),
					1000
				);
		},
		redirectToStoreAdminUrl(storeAdminUrl) {
			window.location.href =
				storeAdminUrl +
				window.location.pathname +
				window.location.search;
		},
	},
	getters: {
		getSelectedStore(state) {
			if (
				!isEmpty(state.selectedStore) &&
				this.userHasAccessToSelectedStore
			)
				return state.selectedStore.clientId;
			return null;
		},
		getSelectedStoreUrl(state) {
			return this.getSelectedStore
				? state.selectedStore.storeWebsite
				: null;
		},
		getSelectedStoreDomain() {
			let storeUrl = this.getSelectedStoreUrl;
			if (!storeUrl) return storeUrl;

			return getMainDomainNameFromUrl(storeUrl);
		},
		userHasAccessToSelectedStore(state) {
			return state.adminStores.includes(
				state.selectedStore.clientId.toString()
			);
		},
		isAdminWithStoreSubdomain() {
			return (
				new String(
					import.meta.env.VITE_FORCE_STORE_SUBDOMAIN
				).toLowerCase() === 'true'
			);
		},
		isOnboardingComplete(state) {
			return state.onboardingCompleted;
		},
	},
});
