<template>
	<component :is="as" class="list-group">
		<slot></slot>
	</component>
</template>

<script>
	export default {
		props: {
			activable: {
				type: Boolean,
				default: false,
			},
			as: {
				type: String,
				default: 'ul',
			},
		},

		data() {
			return {
				items: null,
			};
		},
	};
</script>

<style></style>
