import { defineStore } from 'pinia';
import { useClientsStore } from '@/stores/clients';
import {
	Configuration as KratosConfiguration,
	FrontendApi as KratosPublicApi,
} from '@ory/kratos-client';

import isEmpty from 'lodash/isEmpty';
import { parseStorageJson } from '@/functions/common';

const kratosApi = new KratosPublicApi(
	new KratosConfiguration({
		basePath: import.meta.env.VITE_KRATOS_PUBLIC_API,
		baseOptions: { withCredentials: true },
	}),
	import.meta.env.VITE_KRATOS_PUBLIC_API
);

export const USER_SESSION_KEY = 'userSession';

function isIframe() {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
}

export const useAuthStore = defineStore('auth', {
	state: () => ({
		session: parseStorageJson(USER_SESSION_KEY),
		sessionValidated: false,
		selectedStore: null,
		loginModalVisible: false,
	}),
	actions: {
		setSession(sessionObj) {
			if (isEmpty(sessionObj)) return;
			localStorage.setItem(USER_SESSION_KEY, JSON.stringify(sessionObj));
			this.session = sessionObj;
			this.authenticationStatus = true;
		},
		clearSession() {
			localStorage.removeItem(USER_SESSION_KEY);
			this.session = null;
			this.authenticationStatus = false;
		},
		closeLoginModal() {
			this.loginModalVisible = false;

			// get new session set on storage from iframe
			let newSession = parseStorageJson(USER_SESSION_KEY);
			this.setSession(newSession);

			//set timeout to new session expiry
			setTimeout(() => {
				this.sessionExpired();
			}, new Date(newSession.expires_at).getTime() - Date.now());
		},
		sessionExpired() {
			this.clearSession();
			this.sessionValidated = false;
			this.loginModalVisible = true;
		},
		validateSession() {
			const clientsStore = useClientsStore();
			return new Promise((resolve) => {
				kratosApi
					.toSession()
					.then(({ data: session }) => {
						if (session.active) {
							this.setSession(session);

							if (isIframe()) {
								// iframe used only to login, so close it
								// and go back to main tab
								resolve(session);
								window.parent.closeLoginModal();
								return;
							}

							setTimeout(() => {
								this.sessionExpired();
							}, new Date(session.expires_at).getTime() - Date.now());

							clientsStore
								.getAdminStores(session.identity.id)
								.then(
									() => resolve(session),
									() => resolve('Failed to fetch user stores')
								);
						} else {
							this.clearSession();
							resolve('Session not active');
						}
					})
					.catch((error) => {
						this.clearSession();
						if (error.response && error.response.status == 401) {
							//invalid session
							resolve('No valid session');
						} else {
							resolve('Failed to validate session');
							console.log('Error: ', error);
						}
					})
					.finally(() => (this.sessionValidated = true));
			});
		},
		getLogoutFlowUrl() {
			return kratosApi.createBrowserLogoutFlow();
		},
		getLoginFlow(flowId) {
			return kratosApi.getLoginFlow({ id: flowId });
		},
		getRecoveryFlow(flowId) {
			return kratosApi.getRecoveryFlow({ id: flowId });
		},
		getRegistrationFlow(flowId) {
			return kratosApi.getRegistrationFlow({ id: flowId });
		},
		getSettingsFlow(flowId) {
			return kratosApi.getSettingsFlow({ id: flowId });
		},
		getVerificationFlow(flowId) {
			return kratosApi.getVerificationFlow({ id: flowId });
		},
		getError(errorId) {
			return kratosApi.getFlowError({ id: errorId });
		},
		logout() {
			this.clearSession();
			// just clean selected store when user manually logs out
			useClientsStore().clearSelectedStore();
		},
	},
	getters: {
		isAuthenticated(state) {
			return (
				!isEmpty(state.session) &&
				Date.now() < new Date(state.session.expires_at).getTime()
			);
		},
		getLoggedUser(state) {
			return !isEmpty(state.session) ? state.session.identity : {};
		},
		getLoggedUserName() {
			let user = this.getLoggedUser;
			if (isEmpty(user)) return '';
			return [user.traits.name.first, user.traits.name.last]
				.join(' ')
				.trim();
		},
		getUserSessionKey() {
			return USER_SESSION_KEY;
		},
	},
});
