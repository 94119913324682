<template>
	<div class="input-group" ref="input-group">
		<span class="input-group-text" v-if="$slots.prepend">
			<slot name="prepend"></slot>
		</span>
		<slot></slot>
		<span class="input-group-text" v-if="$slots.append">
			<slot name="append"></slot>
		</span>
	</div>
</template>

<script>
	export default {};
</script>

<style lang="scss">
	.input-group > .form-control-wrapper {
		flex: 1;
		margin-left: -1px;
	}
</style>
