<template>
	<div class="toasts">
		<ElToastItem
			v-for="(message, index) in toastMessages"
			:class="message.placement"
			:index="index"
			:key="index"
			:type="message.type"
			:message="message.message"
			:duration="message.duration"
			@message-hidden="increaseHiddenMessages"
		/>
	</div>
</template>

<script>
	import ElToastItem from '@/components/ui/ElToastItem.vue';

	export default {
		components: {
			ElToastItem,
		},

		data() {
			return {
				toastMessagesHidden: 0,
			};
		},

		computed: {
			toastMessages() {
				return [...this.$toastMessages].reverse();
			},
		},

		methods: {
			increaseHiddenMessages() {
				this.setHiddenMessages(this.toastMessagesHidden + 1);
				if (this.toastMessagesHidden === this.$toastMessages.length) {
					this.setHiddenMessages(0);
					this.$toast().clear();
				}
			},

			setHiddenMessages(qty) {
				this.toastMessagesHidden = qty;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.toasts {
		z-index: 9;
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
	}

	.toast {
		animation-fill-mode: forwards;
		box-shadow: 0 0.25rem 0.75rem rgba(#000, 0.1);
		color: null;
		width: 550px;

		// ------
		// button
		button {
			margin-right: -0.5rem;
		}

		// ------
		// transitions
		&.fade-in {
			animation-name: fade-in;
			animation-duration: 0.5s;
		}

		&.fade-out {
			animation-name: fade-out;
			animation-duration: 0.25s;
		}
	}

	// ------
	// animations
	@keyframes fade-in {
		from {
			opacity: 0;
		}
		to {
			opacity: 100%;
		}
	}

	@keyframes fade-out {
		from {
			opacity: 100%;
		}
		to {
			opacity: 0;
		}
	}
</style>
