<template>
	<span class="icon" :title="text">
		<svg :width="width" :height="height" :class="'icon-' + icon">
			<use :xlink:href="`#icon-${icon}`"></use>
		</svg>
	</span>
</template>

<script>
	export default {
		props: {
			height: {
				type: Number,
				default: 20,
			},
			width: {
				type: Number,
				default: 20,
			},
			icon: {
				type: String,
				required: true,
			},
			text: {
				type: String,
				default: '',
			},
		},
	};
</script>

<style lang="scss">
	.btn-icon {
		height: 20px;
		width: 20px;
		position: relative;
		padding: 0;

		&::before {
			content: '';
			background: #f0f0f0;
			position: absolute;
			left: -5px;
			right: -5px;
			bottom: -5px;
			top: -5px;
			transform: scale(0);
			transition: 0.2s transform;
			border-radius: 0.25rem; // same as button
		}
	}

	.btn-icon.btn-link {
		&::before {
			background: #def;
		}
	}

	.btn-icon:hover::before,
	.btn-icon:focus::before {
		transform: scale(1);
	}

	// icons
	.icon {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.icon-trash {
		margin-right: -1px;
	}
</style>
