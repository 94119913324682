<template>
	<div class="close-sidebar-section" v-if="displayMobileSideBar">
		<button class="btn" @click="displaySideBar">
			<svg width="20" height="20" class="me-1">
				<use xlink:href="#icon-close" />
			</svg>
		</button>
	</div>
	<div
		id="sidebar"
		class="nav-sidebar"
		:class="{ 'sidebar-fade-in': displayMobileSideBar }"
	>
		<div class="logo">
			<router-link :to="{ name: 'homepage' }">
				<img :src="$asset().url('/img/new-logo.png')" alt="Lexmodo" />
			</router-link>
			<div class="header-client-info">
				<span class="ms-2">
					{{ clientsStore?.selectedStore?.storeName }}
				</span>
				<!-- <ElIcon icon="triangle-down" /> -->
			</div>
		</div>
		<ul class="sidebar-content-list">
			<li
				v-for="(item, index) in sidebarItems"
				:key="index"
				class="first-level"
			>
				<a
					v-if="!$_.isUndefined(item.route)"
					@click="toggleSubmenu(index)"
					class="sidebar-content-list-element"
					:class="{
						'has-child': item.children,
						'router-link-active':
							isActive(item) || expandedIndex === index,
					}"
				>
					<svg width="20" height="20">
						<use :xlink:href="'#' + item.icon"></use>
					</svg>
					<span> {{ item.title }}</span>
				</a>
				<a
					v-else
					@click="redirectTo(item.url)"
					class="sidebar-content-list-element"
					:class="{
						'has-child': item.children,
						'is-active': item.isExpanded,
					}"
				>
					<svg width="20" height="20">
						<use :xlink:href="'#' + item.icon"></use>
					</svg>
					<span> {{ item.title }}</span>
				</a>

				<transition name="slide-fade">
					<ul
						v-if="
							!$_.isEmpty(item.children) &&
							expandedIndex === index
						"
						class="sidebar-content-list-element-submenu"
					>
						<li
							v-for="children in item.children.data"
							:key="children.title"
							class="second-level"
						>
							<!-- v-if="!$_.isUndefined(children.route)" -->
							<router-link
								:to="{ name: children.route }"
								class="sidebar-content-list-element-submenu-element"
								@click="$emit('display-sidebar', false)"
							>
								<span>{{ children.title }}</span>
							</router-link>
							<!-- <router-link
							v-else
							:to="{
								name: item.route,
								params: children.route_param,
							}"
							class="sidebar-content-list-element-submenu-element"
							@click="item.children.toggle = true"
						>
							<span>{{ children.title }}</span>
						</router-link> -->
						</li>
					</ul>
				</transition>
			</li>
		</ul>
	</div>
</template>

<script>
	import { useAuthStore } from '@/stores/auth';
	import { useClientsStore } from '@/stores/clients';
	export default {
		name: 'AppSidebar',
		props: {
			displayMobileSideBar: {
				type: Boolean,
				default: false,
			},
		},
		setup() {
			const authStore = useAuthStore();
			const clientsStore = useClientsStore();
			return { authStore, clientsStore };
		},

		data() {
			return {
				expandedIndex: null,
				sidebarItems: [
					{
						icon: 'icon-dashboard',
						title: 'Dashboard',
						route: 'homepage',
					},
					{
						icon: 'icon-products',
						title: 'Products',
						route: 'products_index',

						children: {
							toggle: true,
							data: [
								{
									title: 'Products',
									route: 'products_index',
								},
								{
									title: 'Actions',
									route: 'products_actions_index',
								},
								{
									title: 'Bulk update',
									route: 'products_bulk_update',
								},
							],
						},
					},
					{
						icon: 'icon-layers-2',
						title: 'Categories',
						route: 'categories_index',
					},
					{
						icon: 'icon-orders',
						title: 'Orders',
						route: 'orders_index',

						children: {
							toggle: true,
							data: [
								{
									title: 'Orders',
									route: 'orders_index',
								},
								{
									title: 'Draft Orders',
									route: 'draft_orders_index',
								},
								{
									title: 'Abandoned Orders',
									route: 'abandoned_orders_index',
								},
								{
									title: 'Modules script',
									route: 'orders_module_script_index',
								},
							],
						},
					},
					{
						icon: 'icon-users',
						title: 'Customers',
						route: 'customers_index',
					},
					{
						icon: 'icon-apps-2',
						title: 'Apps',
						route: 'plugins',
					},
					{
						icon: 'icon-discount',
						title: 'Discount',
						route: 'discounts_index',

						children: {
							toggle: true,
							data: [
								{
									title: 'Discount codes',
									route: 'discounts_index',
								},
								// {
								// 	title: 'Automatic discounts',
								// 	route: 'discounts_automatic_index',
								// },
							],
						},
					},
					{
						icon: 'icon-credit',
						title: 'Store Credit',
						route: 'credits_index',
						children: {
							toggle: true,
							data: [
								{
									title: 'Store Credit',
									route: 'credits_index',
								},
								{
									title: 'Ledger transactions',
									route: 'ledger_index',
								},
								{
									title: 'Gift card transactions',
									route: 'gift_card_ledger_index',
								},
							],
						},
					},
					{
						icon: 'icon-library-images',
						title: 'Image Library',
						route: 'images_index',
					},
					{
						icon: 'icon-storefront',
						title: 'Storefront',
						route: 'storefront',
						children: {
							toggle: true,
							data: [
								{
									icon: 'icon-laptop-phone',
									title: 'Templates',
									route: 'templates_index',
								},
								{
									icon: 'icon-select',
									title: 'Banners',
									route: 'banners_index',
								},
								{
									icon: 'menu',
									title: this.$t(
										'settings.items.menus.title'
									),
									route: 'menus_index',
								},
								{
									icon: 'file-empty',
									title: 'Pages',
									route: 'pages_index',
								},
							],
						},
					},
					{
						icon: 'icon-blogging',
						title: 'Blogging',
						route: 'blogs_index',
						children: {
							toggle: true,
							data: [
								{
									title: 'Blogs',
									route: 'blogs_index',
								},
								{
									title: 'Blog posts',
									route: 'posts_index',
								},
							],
						},
					},
					{
						icon: 'icon-report',
						title: 'Reports',
						route: 'reports',
						children: {
							toggle: true,
							data: [
								{
									title: 'Customer orders',
									route: 'reports_customer_orders',
								},
								{
									title: 'Sales tax',
									route: 'reports_sales_tax',
								},
							],
						},
					},
					{
						icon: 'icon-cog',
						title: 'Settings',
						route: 'settings',
					},
				],
			};
		},
		emits: ['display-sidebar'],
		methods: {
			toggleSubmenu(index) {
				const item = this.sidebarItems[index];
				if (item.children) {
					this.expandedIndex =
						this.expandedIndex === index ? null : index;
				} else if (item.route) {
					this.expandedIndex = null;
					this.$router.push({ name: item.route });
					this.$emit('display-sidebar', false);
				} else if (item.url) {
					this.redirectTo(item.url);
				}
			},
			isActive(item) {
				if (item.route) {
					return (
						this.$route.name === item.route ||
						(item.children &&
							item.children.data.some(
								(child) => this.$route.name === child.route
							))
					);
				}
				return false;
			},
			redirectTo(url) {
				window.location.href = url;
			},
			displaySideBar() {
				this.$emit('display-sidebar', false);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.sidebar-content-list-element {
		display: flex;
		align-items: center;
		transition: background-color 0.3s ease, color 0.3s ease;
		padding: 10px 15px;
		cursor: pointer;

		&:hover {
			background-color: rgba(0, 0, 0, 0.02);
		}

		.chevron-icon {
			margin-left: auto;
			transition: transform 0.3s ease;
		}
	}

	.sidebar-content-list-element-submenu {
		display: none;
		list-style-type: none;
		padding-left: 0;

		a {
			padding-left: 60px;
			display: block;
			transition: background-color 0.3s ease;

			&:hover {
				background-color: rgba(0, 0, 0, 0.02);
			}
		}

		a span {
			font-weight: 400;
		}
	}

	.router-link-active,
	.router-link-exact-active {
		font-weight: bold;
		background-color: rgba(0, 0, 0, 0.05);
		color: #007bff; // You can replace this with your preferred highlight color

		+ .sidebar-content-list-element-submenu {
			display: block;
		}
	}

	// Chevron rotation
	.router-link-active .chevron-icon,
	.router-link-exact-active .chevron-icon {
		transform: rotate(180deg);
	}
</style>
