<template>
	<div class="form-divider my-4"></div>
</template>

<script>
	export default {};
</script>

<style scoped lang="scss">
	.form-divider {
		background-color: var(--bs-light);
		height: 1px;
	}
</style>
