import i18n from '@/lang';

export default {
	install: (app) => {
		app.config.globalProperties.$clipboard = () => {
			const execClipboardFunction = async (fn, data, showToast) => {
				try {
					data
						? await navigator.clipboard[fn](data)
						: await navigator.clipboard[fn]();
					if (showToast)
						app.config.globalProperties.$showToastSuccess(
							i18n.global.t('components.clipboard.success'),
							1500
						);
				} catch (e) {
					if (showToast)
						app.config.globalProperties.$showToastError(
							`${i18n.global.t(
								'components.clipboard.error'
							)} ${e}`,
							1500
						);
				}
			};

			const write = (data, showToast = true) =>
				execClipboardFunction('write', data, showToast);

			const writeText = (data, showToast = true) =>
				execClipboardFunction('writeText', data, showToast);

			const read = () => execClipboardFunction('read');

			const readText = () => execClipboardFunction('readText');

			return {
				write,
				writeText,
				read,
				readText,
			};
		};
	},
};
