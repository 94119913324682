export default {
	install: (app) => {
		app.config.globalProperties.$worker = function () {
			const decodeWorker = new Worker(
				new URL('@/functions/workers/decodeWorker.js', import.meta.url),
				{ type: 'module' }
			);

			const encodeWorker = new Worker(
				new URL('@/functions/workers/encodeWorker.js', import.meta.url),
				{ type: 'module' }
			);

			const base64ToUtf8 = (data) => {
				return new Promise((resolve, reject) => {
					try {
						decodeWorker.addEventListener('message', (e) => {
							resolve(e.data);
						});
						decodeWorker.postMessage(data);
					} catch (e) {
						reject(e);
					}
				});
			};

			const utf8ToBase64 = (data) => {
				return new Promise((resolve, reject) => {
					try {
						encodeWorker.addEventListener('message', (e) => {
							resolve(e.data);
						});
						encodeWorker.postMessage(data);
					} catch (e) {
						reject(e);
					}
				});
			};

			return {
				base64ToUtf8,
				utf8ToBase64,
			};
		};
	},
};
