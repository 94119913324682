<template>
	<textarea
		v-bind="$attrs"
		class="form-control"
		:value="modelValue"
		@input="$emit('update:modelValue', $event.target.value)"
	></textarea>
</template>

<script>
	export default {
		props: {
			modelValue: {
				type: String,
				default: '',
			},
		},
		emits: ['update:modelValue'],
	};
</script>

<style scoped>
	textarea.form-control {
		min-height: 120px;
	}
</style>
