<template>
	<div
		v-if="show"
		class="alert"
		:class="{
			'd-flex justify-content-between align-items-center pe-0':
				dismissable,
		}"
		role="alert"
	>
		<slot></slot>
		<ElButton class="close" @click="onClick" v-if="dismissable">
			<ElIcon icon="cross" :tooltip="undefined" />
		</ElButton>
	</div>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: true,
				required: false,
			},
			dismissable: {
				type: Boolean,
				default: false,
				required: false,
			},
		},

		emits: ['dismiss'],

		methods: {
			onClick() {
				this.$emit('dismiss');
			},
		},
	};
</script>

<style></style>
