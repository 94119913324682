<template>
	<div class="card">
		<div class="card-header" v-if="title">
			<span>{{ title }}</span>
			<slot name="card-header"></slot>
		</div>
		<div class="card-body" :class="bodyClass">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: null,
			},
			bodyClass: {
				type: String,
				default: '',
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card-header {
		display: flex;

		> span:first-child {
			font-weight: 600;
			font-size: 18px;
		}
	}
</style>
