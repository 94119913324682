export default {
	install: (app) => {
		app.config.globalProperties.$asset = function () {
			const resizeCdnImage = (imgUrl, width, height) => {
				// Check if img exists and is from imagekit
				if (imgUrl && imgUrl.includes('.imagekit.io')) {
					let urlArr = imgUrl.split('/');
					urlArr.splice(
						-1,
						0,
						`tr:w-${width ? width : ''},h-${height ? height : ''}`
					);

					let reconstructedUrl = urlArr.join('/');

					return reconstructedUrl;
				} else return imgUrl;
			};

			const url = (asset) => {
				if (process.env.NODE_ENV === 'production')
					return 'https://cdn.lexmodo.com/adm_assets' + asset;

				return asset;
			};

			return { url, resizeCdnImage };
		};
	},
};
