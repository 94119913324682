import { createI18n } from 'vue-i18n';
import global from '@/lang/lang_global';
import category from '@/lang/lang_categories';
import product from '@/lang/lang_products';
import order from '@/lang/lang_orders';
import customer from '@/lang/lang_customers';
import address from '@/lang/lang_addresses';
import cart from '@/lang/lang_carts';
import shippings from '@/lang/lang_shippings';
import payments from '@/lang/lang_payments';
import menus from '@/lang/lang_menus';
import settings from '@/lang/lang_settings';
import plugins from '@/lang/lang_plugins';
import discounts from '@/lang/lang_discounts';
import credits from '@/lang/lang_credits';
import taxes from '@/lang/lang_taxes';
import locations from '@/lang/lang_locations';
import labels from '@/lang/lang_labels';
import templates from '@/lang/lang_templates';
import themes from '@/lang/lang_themes';
import banners from '@/lang/lang_banners';
import auth from '@/lang/lang_auth';
import clients from '@/lang/lang_clients';
import images from '@/lang/lang_images';
import pages from '@/lang/lang_pages';
import blogs from '@/lang/lang_blogs';
import rules from '@/lang/lang_rules';
import redirects from '@/lang/lang_redirects';
import reports from '@/lang/lang_reports';
import accounts from '@/lang/lang_accounts';
import validations from '@/lang/lang_validations';

// en only
const messages = {
	en: Object.assign(
		global.en,
		category.en,
		product.en,
		order.en,
		customer.en,
		address.en,
		cart.en,
		shippings.en,
		payments.en,
		menus.en,
		settings.en,
		plugins.en,
		discounts.en,
		credits.en,
		taxes.en,
		locations.en,
		labels.en,
		templates.en,
		themes.en,
		banners.en,
		auth.en,
		clients.en,
		images.en,
		pages.en,
		blogs.en,
		rules.en,
		reports.en,
		redirects.en,
		accounts.en,
		validations.en
	),
};

export default createI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages,
	silentTranslationWarn: true,
});
