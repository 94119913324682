<template>
	<div class="invalid-feedback">
		<slot></slot>
	</div>
</template>

<script>
	export default {};
</script>

<style></style>
