import { getStoreCurrency } from '@/functions/common';

export default {
	install: (app) => {
		app.config.globalProperties.$price = function () {
			let formatter = (formatterCurrency) =>
				new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: formatterCurrency
						? formatterCurrency
						: getStoreCurrency(),
				});

			/**
			 *
			 * @param {*} price
			 */
			let numbers = (price) =>
				String(price)
					.replace(/[^0-9.]/g, '')
					.trim();

			/**
			 *
			 * @param {*} number
			 */
			let currencySymbol = (formatterCurrency, number = 500) => {
				let parts = formatter(formatterCurrency).formatToParts(number); // can be any number
				let currency = parts.find((part) => part.type === 'currency');

				return currency.value;
			};

			/**
			 *
			 * @param {*} price
			 */
			let cents = (price) => {
				if (typeof price === 'object') return price.amount;

				return price;
			};

			/**
			 *
			 * @param {*} cents
			 */
			let toDollars = (cents) =>
				isNaN(cents) ? cents : parseFloat((cents / 100).toFixed(2));

			/**
			 *
			 * @param {*} dollars
			 */
			let toCents = (dollars) =>
				isNaN(dollars) ? dollars : Math.round(dollars * 100);

			/**
			 *
			 * @param {*} cents
			 * @param {*} showCurrency
			 */
			let format = function (
				cents,
				showCurrency = true,
				formatterCurrency
			) {
				if (typeof cents !== 'undefined') {
					if (typeof cents === 'object') cents = cents.amount;

					if ((cents === '' || cents === 0) && !showCurrency)
						return '';

					let price = formatter(formatterCurrency).format(
						toDollars(cents)
					);

					if (!showCurrency) return numbers(price);

					return price;
				}
			};

			/**
			 *
			 * @param {*} obj
			 * @param {*} key
			 * @param {*} dollars
			 */
			let set = (obj, key, dollars) => {
				obj[key] = toCents(dollars);
			};

			return {
				set,
				format,
				currencySymbol,
				cents,
				toCents,
			};
		};

		/**
		 * TODO: move from price
		 */
		app.config.globalProperties.setFormatPercentToDecimal = function (
			tag,
			fieldPrice,
			stringPercent
		) {
			stringPercent = stringPercent == '' ? '0' : stringPercent;

			var decimalPercent = parseFloat(stringPercent) / 100;
			var decimalPercentString = decimalPercent.toFixed(2);

			if (typeof tag == 'object') {
				this[tag[0]][tag[1]][fieldPrice] = decimalPercentString;
			} else {
				this[tag][fieldPrice] = decimalPercentString;
			}
		};

		app.config.globalProperties.setFormatDecimalToPercent = function (
			stringDecimal
		) {
			stringDecimal = stringDecimal == '' ? '0' : stringDecimal;

			var fullPercent = parseFloat(stringDecimal) * 100;
			var fullPercentString = fullPercent; // .toFixed(2)

			return fullPercentString;
		};

		// TODO: move from price
		app.config.globalProperties.calculatePriceFromAdjustAndSave = function (
			adjustDataObject,
			currentPriceCent
		) {
			if (adjustDataObject.orderLineItemRate == 2) {
				let adjustPriceCent =
					adjustDataObject.orderLineItemAppliedDollarAmount;

				// amount
				currentPriceCent =
					adjustDataObject.binaryOperator === 0
						? currentPriceCent + adjustPriceCent
						: currentPriceCent - adjustPriceCent;
			} else {
				let adjustPricePercent =
					adjustDataObject.orderLineItemAppliedDecimalPercentage;

				//percent
				if (adjustDataObject.binaryOperator === 0) {
					currentPriceCent +=
						currentPriceCent * parseFloat(adjustPricePercent);
				} else {
					currentPriceCent -=
						currentPriceCent * parseFloat(adjustPricePercent);
				}
			}

			return currentPriceCent;
		};
	},
};
