import { createApp } from 'vue';
import { createPinia } from 'pinia';
import '@/assets/scss/app.scss';

import i18n from '@/lang';
import router from '@/router/routes';

import * as Sentry from '@sentry/vue';

import App from '@/App.vue';
import ViewHeader from '@/components/ViewHeader.vue';

import ElAlert from '@/components/ui/ElAlert.vue';
import ElBadge from '@/components/ui/ElBadge.vue';
import ElButton from '@/components/ui/ElButton.vue';
import ElBreadcrumb from '@/components/ui/ElBreadcrumb.vue';
import ElCard from '@/components/ui/ElCard.vue';
import ElDropdown from '@/components/ui/ElDropdown.vue';
import ElDropdownItem from '@/components/ui/ElDropdownItem.vue';
import ElFormInvalidFeedback from '@/components/ui/ElFormInvalidFeedback.vue';
import ElFieldset from '@/components/ui/ElFieldset.vue';
import ElFormDivider from '@/components/ui/ElFormDivider.vue';
import ElFormGroup from '@/components/ui/ElFormGroup.vue';
import ElFormText from '@/components/ui/ElFormText.vue';
import ElFormSubheading from '@/components/ui/ElFormSubheading.vue';
import ElGridRow from '@/components/ui/ElGridRow.vue';
import ElGridCol from '@/components/ui/ElGridCol.vue';
import ElLabel from '@/components/ui/ElLabel.vue';
import ElLegend from '@/components/ui/ElLegend.vue';
import ElIcon from '@/components/ui/ElIcon.vue';
import ElImage from '@/components/ui/ElImage.vue';
import ElInput from '@/components/ui/ElInput.vue';
import ElTextarea from '@/components/ui/ElTextarea.vue';
import ElInputGroup from '@/components/ui/ElInputGroup.vue';
import ElInputRadio from '@/components/ui/ElInputRadio.vue';
import ElInputCheckbox from '@/components/ui/ElInputCheckbox.vue';
import ElListGroup from '@/components/ui/ElListGroup.vue';
import ElListGroupItem from '@/components/ui/ElListGroupItem.vue';
import ElMedia from '@/components/ui/ElMedia.vue';
import ElModal from '@/components/ui/ElModal.vue';
import ElToast from '@/components/ui/ElToast.vue';
import ElLoading from '@/components/ui/ElLoading.vue';

import date from '@/helpers/date';
import price from '@/helpers/price';
import toast from '@/helpers/toast';
import unit from '@/helpers/unit';
import asset from '@/helpers/asset';
import lodash from '@/helpers/lodash';
import worker from '@/helpers/worker';
import clipboard from '@/helpers/clipboard';

import TooltipDirective from './directives/v-tooltip';

import { createMetaManager, plugin as metaPlugin } from 'vue-meta';

import VueGapi from 'vue-gapi';

import {
	imageMaxSize,
	phoneDefaultCountry,
	worldWideTaxCode,
	prioritizedCountries,
	eelPfcExemptCode,
} from '@/config/appConstants';

const pinia = createPinia();
const app = createApp(App);
const metaManager = createMetaManager();

if (import.meta.env.PROD)
	Sentry.init({
		app,
		dsn: 'https://ceb632fd95f011e89a6eb30c1b4c5869@o4507255777591296.ingest.us.sentry.io/4507255780016128',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		ignoreErrors: [
			'Network Error',
			'Non-Error promise rejection captured with value: Connection Error',
			'Importing a module script failed.',
			/^Failed to fetch dynamically imported module: .*/i,
			/^Unable to preload CSS for .*/i,
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [/^https:\/\/.*\.lexmodo\.com/], //'localhost',
		// Session Replay
		replaysSessionSampleRate: 0.5, // This sets the sample rate at 50%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});

app.use(router);
app.use(i18n);

app.use(metaManager);
app.use(metaPlugin);
app.use(date);
app.use(price);
app.use(toast);
app.use(unit);
app.use(asset);
app.use(lodash);
app.use(worker);
app.use(clipboard);

pinia.use(({ store }) => {
	store.$asset = app.config.globalProperties.$asset;
	store.$date = app.config.globalProperties.$date;
	store.$unit = app.config.globalProperties.$unit;
});
app.use(pinia);

app.use(VueGapi, {
	access_type: 'offline',
	apiKey: 'AIzaSyAgfGIqu_rdgBIBLWifogmvmAsrppmHtBc',
	clientId:
		'66399128605-3gugqp20pkmsnqfmkr9km4qrnjiv52ko.apps.googleusercontent.com',
	scope: 'https://www.googleapis.com/auth/analytics.readonly',
});

app.component('ViewHeader', ViewHeader);
app.component('ElAlert', ElAlert);
app.component('ElBadge', ElBadge);
app.component('ElButton', ElButton);
app.component('ElBreadcrumb', ElBreadcrumb);
app.component('ElCard', ElCard);
app.component('ElDropdown', ElDropdown);
app.component('ElDropdownItem', ElDropdownItem);
app.component('ElFormInvalidFeedback', ElFormInvalidFeedback);
app.component('ElFieldset', ElFieldset);
app.component('ElFormDivider', ElFormDivider);
app.component('ElFormGroup', ElFormGroup);
app.component('ElFormText', ElFormText);
app.component('ElFormSubheading', ElFormSubheading);
app.component('ElGridRow', ElGridRow);
app.component('ElGridCol', ElGridCol);
app.component('ElLabel', ElLabel);
app.component('ElLegend', ElLegend);
app.component('ElIcon', ElIcon);
app.component('ElImage', ElImage);
app.component('ElInput', ElInput);
app.component('ElTextarea', ElTextarea);
app.component('ElInputGroup', ElInputGroup);
app.component('ElInputRadio', ElInputRadio);
app.component('ElInputCheckbox', ElInputCheckbox);
app.component('ElListGroup', ElListGroup);
app.component('ElListGroupItem', ElListGroupItem);
app.component('ElMedia', ElMedia);
app.component('ElModal', ElModal);
app.component('ElToast', ElToast);
app.component('ElLoading', ElLoading);

app.directive('tooltip', TooltipDirective);

app.config.globalProperties.$imageMaxSize = imageMaxSize;
app.config.globalProperties.$phoneDefaultCountry = phoneDefaultCountry;
app.config.globalProperties.$worldWideTaxCode = worldWideTaxCode;
app.config.globalProperties.$prioritizedCountries = prioritizedCountries;
app.config.globalProperties.$eelPfcExemptCode = eelPfcExemptCode;
app.config.globalProperties.$messages = [];

app.mount('#app');
