<template>
	<component
		:is="as"
		class="list-group-item"
		:class="{ active: isActive }"
		@click="onClick($event)"
	>
		<slot></slot>
	</component>
</template>

<script>
	export default {
		props: {
			active: {
				type: Boolean,
				default: false,
			},
			as: {
				type: String,
				default: 'li',
			},
		},

		emits: ['click', 'update:active', 'unselect-siblings'],

		data() {
			return {};
		},

		computed: {
			isActive: {
				get() {
					return this.active;
				},
				set(val) {
					this.$emit('update:active', val);
				},
			},
		},

		methods: {
			onClick(event) {
				this.$emit('click', event);
				if (this.$parent.activable) this.selectItem();
			},

			selectItem() {
				this.$emit('unselect-siblings');
				//wait next tick so all are already unselected
				this.$nextTick(() => {
					this.isActive = true;
				});
			},

			// unSelectItems(items) {
			// 	items.forEach((child) => (child.isActive = false));
			// },
		},
	};
</script>

<style scoped>
	.list-group-item.active {
		pointer-events: none;
	}
</style>
