<template>
	<div class="form-check">
		<input
			type="radio"
			class="form-check-input"
			:name="name"
			:id="id"
			:value="value"
			:checked="shouldBeChecked"
			:required="required"
			:disabled="disabled"
			@change="updateInput($event)"
		/>
		<label class="form-check-label" :for="id">
			<slot></slot>
		</label>
	</div>
</template>

<script>
	export default {
		props: {
			name: {
				type: String,
				default: null,
			},
			checked: {
				type: Boolean,
				default: false,
			},
			modelValue: {
				type: [String, Number, Object, Boolean],
				default: undefined,
			},
			required: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			value: {
				type: [String, Number, Object, Boolean],
				default: true,
			},
		},

		emits: ['change', 'update:modelValue'],

		data() {
			return {
				id: 'radio-id-' + this._.uid,
			};
		},

		computed: {
			shouldBeChecked() {
				return this.modelValue === this.value;
			},
		},

		methods: {
			updateInput(event) {
				this.$emit('change', event);
				this.$emit('update:modelValue', this.value);
			},
		},

		mounted() {
			if (this.checked) {
				this.updateInput();
			}
		},
	};
</script>

<style scoped>
	.list-group-item--choosable {
		border-color: #e9e9e9;
		padding: 0;
	}

	.list-group-item--choosable label {
		cursor: pointer;
		display: block;
		padding: 1rem;
	}

	.list-group-item--choosable label:before,
	.list-group-item--choosable label:after {
		display: none;
	}

	.list-group-item--choosable input:focus ~ label,
	.list-group-item--choosable .active {
		background: unset;
		background: unset;
		box-shadow: 0 0 3px 0px #1a92ee;
		border-color: #1a92ee;
	}
</style>
