<template>
	<header id="header">
		<div class="header-elements">
			<!-- Sidebar mobile button  -->
			<button
				class="btn button-show-sidebar ms-3"
				@click="displaySideBar()"
			>
				<svg width="20" height="20" class="me-1">
					<use xlink:href="#sidebar-button" />
				</svg>
			</button>

			<a
				v-if="showVisitStoreButton"
				class="header-store-link"
				target="_blank"
				:href="storeUrl"
			>
				<svg width="20" height="20" class="me-1">
					<use xlink:href="#icon-view-store" />
				</svg>
				{{ $t('button.view_store') }}
			</a>

			<ElDropdown
				class="header-account ms-auto me-3"
				v-if="!$_.isEmpty(loggedUser)"
			>
				<template #dropdown-trigger>
					<img
						class="header-account-picture"
						:src="`https://ui-avatars.com/api/?background=C9E7FF&color=1170B9&bold=true&name=${loggedUser.fullName}`"
					/>
					<span class="ms-2">
						{{ loggedUser.fullName }}
					</span>
					<ElIcon icon="triangle-down" />
				</template>
				<template #dropdown-content>
					<ElDropdownItem @click="myAccountPage">
						{{ $t('button.my_profile') }}
					</ElDropdownItem>
					<ElDropdownItem
						v-if="hasMultipleStores"
						@click="$router.push({ name: 'store_selection' })"
					>
						{{ $t('button.switch_store') }}
					</ElDropdownItem>
					<ElDropdownItem @click="$router.push({ name: 'logout' })">
						{{ $t('button.logout') }}
					</ElDropdownItem>
				</template>
			</ElDropdown>
		</div>
	</header>
</template>

<script>
	import { useAuthStore } from '@/stores/auth';
	import { useClientsStore } from '@/stores/clients';

	export default {
		name: 'AppHeader',
		setup() {
			const clientsStore = useClientsStore();
			const authStore = useAuthStore();
			return { clientsStore, authStore };
		},
		props: {
			showVisitStoreButton: {
				type: Boolean,
				default: true,
			},
		},
		emits: ['display-sidebar'],
		data() {
			return {
				loggedUser: {},
				templatesList: [],
			};
		},
		methods: {
			loadUserData() {
				this.loggedUser = Object.assign(
					{},
					this.authStore.getLoggedUser,
					{
						fullName: this.authStore.getLoggedUserName,
					}
				);
			},
			myAccountPage() {
				window.location.href =
					import.meta.env.VITE_KRATOS_PUBLIC_API +
					'/self-service/settings/browser';
			},
			displaySideBar() {
				this.$emit('display-sidebar', true);
			},
		},
		computed: {
			hasMultipleStores() {
				return this.clientsStore.adminStores.length > 1;
			},
			storeUrl() {
				return `https://${this.clientsStore.getSelectedStoreUrl}`;
			},
		},
		created() {
			this.loadUserData();
		},
	};
</script>
