import { defineStore } from 'pinia';
import { useClientsStore } from '@/stores/clients';

import { grpc } from '@improbable-eng/grpc-web';
import { Products } from 'lex-proto/product_pb_service';
import {
	ProductVariant,
	VariantsArray,
	ProductListingInfo,
	ProductInfoRequest,
	ProductInfoUpdateArrayString,
	ProductCreateRequest,
	VariantsCreate,
	ProductInfoUpdateCustomFields,
	ProductSearchRequest,
	ProductRuleRequest,
	ProductEmptyRequest,
	ProductVariantSaleRequest,
	VariantAvailableInventory,
	VariantLocationQty,
	Weight,
	DIM,
	PRODUCT_CHANNEL,
	ImageUpload,
	ProductVariantUpdateString,
	RuleSortBy,
	StockInternalInfo,
	InventoryLogRequest,
	ProductAtlTextUpdate,
	ProductAction,
	QtyDiscount,
	ProductVariantQtyDiscount,
	ProductIDByVariantRequest,
	ProductVariantOptionDelete,
	OptionValueObject,
} from 'lex-proto/product_pb';
import { BINARY_OPERATOR, Money } from 'lex-proto/money_pb';
import {
	onMessageResponseAsync,
	onEndResponseAsync,
} from '@/functions/handleGrpcResponse';

import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import md5 from 'md5';

import { StreamUploadHelper } from '@/functions/streamUploadHelper';

import {
	formatSeoTitle,
	formatSeoUrl,
	formatSeoDescription,
} from '@/functions/seo';

import {
	capitalize,
	fieldSetter,
	getStoreCurrency,
	resourceListingSet,
} from '@/functions/common';

const concordanceFunctionUpdate = {
	SetProductVariantCustomFieldsMap: 'SetProductVariantCustomFields',
	SetProductVariantOptionValueList: 'SetProductVariantOptionValue',
	SetProductStockInternalInfo: 'SetProductVariantInternalStock',
	SetProductImagesList: 'SetProductImages',
	SetProductDimType: 'SetProductDIM',
	SetProductTagsList: 'SetProductTags',
	SetProductOptionList: 'SetProductOptions',
	SetProductChannelsList: 'SetProductChannels',
};

import {
	Empty,
	StringValue,
	Int32Value,
} from 'google-protobuf/google/protobuf/wrappers_pb';

function buildVariantStockInternalInfo(stockInternalInfo) {
	let stockInternalRequest = new StockInternalInfo();
	if (stockInternalInfo) {
		stockInternalRequest.setReorderingPoint(
			stockInternalInfo.reorderingPoint
		);
		stockInternalRequest.setDesiredInventoryLevel(
			stockInternalInfo.desiredInventoryLevel
		);

		let costMoney = new Money();
		costMoney.setAmount(stockInternalInfo.cost.amount);
		stockInternalRequest.setCost(costMoney);

		let msrpMoney = new Money();
		msrpMoney.setAmount(stockInternalInfo.msrp.amount);
		stockInternalRequest.setMsrp(msrpMoney);

		stockInternalInfo.variantOptionValueBreakdownMap.forEach(([v, m]) => {
			let moneyObj = new Money();
			moneyObj.setAmount(m.amount);

			stockInternalRequest
				.getVariantOptionValueBreakdownMap()
				.set(v, moneyObj);
		});

		return stockInternalRequest;
	}
}

export const useProductsStore = defineStore('products', {
	state: () => ({
		visibleProductImportModal: false,
	}),
	actions: {
		showProductsImportModal() {
			this.visibleProductImportModal = true;
		},
		hideProductsImportModal() {
			this.visibleProductImportModal = false;
		},
		getProductBySeoUrl(productSeoUrl) {
			return new Promise((resolve, reject) => {
				const getProductsRequest = new ProductInfoRequest();
				getProductsRequest.setProductSeoUrl(productSeoUrl);

				grpc.invoke(Products.GetProductByID, {
					request: getProductsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		getProduct(productId) {
			return new Promise((resolve, reject) => {
				const getProductsRequest = new ProductInfoRequest();
				getProductsRequest.setProductId(productId);

				grpc.invoke(Products.GetProductByID, {
					request: getProductsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		updateCustomFields([dataCustom, productId]) {
			return new Promise((resolve, reject) => {
				let customFieldsListRequest =
					new ProductInfoUpdateCustomFields();
				customFieldsListRequest.setProductId(productId);

				if (!isEmpty(dataCustom)) {
					dataCustom.forEach(([key, val]) =>
						customFieldsListRequest.getValueMap().set(key, val)
					);
				}

				grpc.invoke(Products.SetProductCustomFields, {
					request: customFieldsListRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		updateFields(data) {
			return new Promise((resolve, reject) => {
				let [
					fieldName,
					fieldValue,
					productId,
					variantPrefix, // prefix empty for product / product for variant
					variantsList, // list variant to update (when update all variant at the same time)
				] = data;

				if (isUndefined(variantPrefix)) variantPrefix = '';
				let fnService = `Set${variantPrefix}${capitalize(fieldName)}`;

				// adapt to api service name
				if (typeof concordanceFunctionUpdate[fnService] === 'string') {
					fnService = concordanceFunctionUpdate[fnService];
				}
				let requestUpdate = new Products[fnService].requestType();
				//dimensions
				if (fnService == 'SetProductVariantDimensions') {
					fieldValue.forEach((v) => {
						let fnServiceDim = v[0].replace('variant', 'setValue');
						requestUpdate[fnServiceDim](v[1]);
					});
				} else if (fnService == 'SetProductVariantInternalStock') {
					requestUpdate.setStockInternalInfo(
						buildVariantStockInternalInfo(fieldValue)
					);
				} else if (fnService == 'SetRelatedProducts') {
					requestUpdate.setRelatedProductIdList(fieldValue);
				} else {
					if (!isUndefined(requestUpdate.setValue)) {
						if (typeof fieldValue == 'object') {
							requestUpdate.setValue(fieldValue.value);
						} else {
							requestUpdate.setValue(fieldValue);
						}
					} else if (!isUndefined(requestUpdate.getValueMap)) {
						fieldValue.forEach(([key, val]) =>
							requestUpdate.getValueMap().set(key, val)
						);
					} else {
						requestUpdate.setValueList(fieldValue);
					}
				}

				requestUpdate.setProductId(productId);

				// force to execute once (for product) when no variants
				if (isEmpty(variantsList)) variantsList = ['isProduct'];

				variantsList.forEach((variantId) => {
					if (variantId !== 'isProduct') {
						requestUpdate.setVariantId(variantId);
					}

					grpc.invoke(Products[fnService], {
						request: requestUpdate,
						host: import.meta.env.VITE_API_URL,
						metadata: {
							'x-client-id': useClientsStore().getSelectedStore,
						},
						onMessage: (message) =>
							onMessageResponseAsync(message, resolve, reject),
						onEnd: (code, msg, trailers) =>
							onEndResponseAsync(
								code,
								msg,
								trailers,
								resolve,
								reject
							),
					});
				});
			});
		},
		uploadImage([dataImage, productId, variantId]) {
			return new Promise((resolve, reject) => {
				useClientsStore()
					.getAdminToken()
					.then((tokenResponse) => {
						let uploadToken = tokenResponse.accessToken;

						const imageUploadRequest = new ImageUpload();
						imageUploadRequest.setProductId(productId);
						imageUploadRequest.setImageName(dataImage.name);

						if (
							typeof variantId !== 'undefined' &&
							variantId != ''
						) {
							imageUploadRequest.setVariantId(variantId);
						}

						let responseMessages = [];
						const imageUploadStream = grpc.client(
							Products.UploadProductVariantImage,
							{
								host: import.meta.env.VITE_API_URL,
								transport: grpc.WebsocketTransport({
									methodDefinition:
										Products.UploadProductVariantImage,
									url: import.meta.env.VITE_API_URL,
								}),
							}
						);

						let streamHelper = new StreamUploadHelper();
						streamHelper.onChunkRead = (dataRead) => {
							imageUploadRequest.setFileContent(dataRead);
							imageUploadStream.send(imageUploadRequest);
						};
						streamHelper.onDoneReading = () =>
							imageUploadStream.finishSend();

						imageUploadStream.onHeaders(() => {});
						imageUploadStream.onMessage((message) => {
							responseMessages.push(message.toObject());
						});
						imageUploadStream.onEnd((code, msg, trailers) => {
							if (code == grpc.Code.OK) {
								resolve(responseMessages);
							} else {
								reject(code, msg, trailers);
							}
							streamHelper.breakExecutionOnNextChunk();
						});

						//start transmission
						imageUploadStream.start({
							authorization: 'Bearer ' + uploadToken,
						});

						//start uploading files
						streamHelper.parseContentInChunks(dataImage);
					})
					.catch((error) => reject([error]));
			});
		},
		deleteImage([imageLink, productId, variantId]) {
			return new Promise((resolve, reject) => {
				const deleteImageRequest = new ProductVariantUpdateString();
				deleteImageRequest.setProductId(productId);
				deleteImageRequest.setValue(imageLink);

				if (typeof variantId !== 'undefined' && variantId != '') {
					deleteImageRequest.setVariantId(variantId);
				}

				grpc.invoke(Products.DeleteProductVariantImage, {
					request: deleteImageRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		sortImages([productId, imagesOrderArray]) {
			return new Promise((resolve, reject) => {
				let imagesSortRequest = new ProductInfoUpdateArrayString();
				imagesSortRequest.setProductId(productId);
				imagesSortRequest.setValueList(imagesOrderArray);

				grpc.invoke(Products.SetProductImagesOrder, {
					request: imagesSortRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		setProductImageAltText([productId, imageUrl, altText, variantId]) {
			return new Promise((resolve, reject) => {
				let altTextRequest = new ProductAtlTextUpdate();
				altTextRequest.setProductId(productId);
				altTextRequest.setImageUrlHash(md5(imageUrl));
				altTextRequest.setAltText(altText);

				if (!isEmpty(variantId)) altTextRequest.setVariantId(variantId);

				grpc.invoke(Products.SetProductImageAltText, {
					request: altTextRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		addVariant([dataVariant, productId]) {
			return new Promise((resolve, reject) => {
				//if single object transform in array
				let listVariant = Array.isArray(dataVariant)
					? dataVariant
					: [dataVariant];

				const variantsRequest = new VariantsArray();
				variantsRequest.setProductId(productId);

				listVariant.forEach((variantInfo) => {
					const variantsCreate = new VariantsCreate();

					for (let fieldName in variantInfo) {
						let fnName = fieldSetter(fieldName);
						if (isUndefined(variantsCreate[fnName])) continue;

						// variant sale info
						if (
							fnName == 'setVariantSaleStart' ||
							fnName == 'setVariantSaleEnd'
						) {
							if (
								isUndefined(variantInfo[fieldName]) ||
								isNaN(parseInt(variantInfo[fieldName]))
							)
								continue;

							let setValueUpdate = new Int32Value();
							setValueUpdate.setValue(
								parseInt(variantInfo[fieldName])
							);
							variantsCreate[fnName](setValueUpdate);
							continue;
						}

						if (fnName == 'setStockInternalInfo') {
							variantsCreate[fnName](
								buildVariantStockInternalInfo(
									variantInfo[fieldName]
								)
							);
							continue;
						}

						// variant prices
						if (fnName.includes('Price')) {
							if (typeof variantInfo[fieldName] == 'object') {
								// money object type
								variantsCreate[fnName](
									variantInfo[fieldName].amount
								);
							} else {
								variantsCreate[fnName](variantInfo[fieldName]);
							}
							continue;
						}

						//other fields
						variantsCreate[fnName](variantInfo[fieldName]);
					}
					variantsRequest.addProductVariants(variantsCreate);
				});

				grpc.invoke(Products.CreateVariants, {
					request: variantsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},

					onMessage: (message) =>
						onMessageResponseAsync(
							message,
							resolve,
							reject,
							'product'
						),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		createNew(productData) {
			return new Promise((resolve, reject) => {
				const productCreateRequest = new ProductCreateRequest();

				let productManualFields = [
					'productId',
					'productImagesList',
					'productCustomFieldsMap',
					'productVariantsList',
				];

				let variantManualFields = [
					'variantId',
					'variantSaleEnd',
					'variantSaleStart',
					'stockInternalInfo',
					'variantCustomFieldsMap',
				];

				// --- Product data
				for (let fieldName in productData) {
					if (
						productManualFields.includes(fieldName) ||
						isUndefined(
							productCreateRequest[fieldSetter(fieldName)]
						)
					)
						continue;

					productCreateRequest[fieldSetter(fieldName)](
						productData[fieldName]
					);
				}

				//validate product data available
				let dateNow = Math.floor(new Date().getTime() / 1000);
				if (productCreateRequest.getProductDateAvailable() < dateNow)
					productCreateRequest.setProductDateAvailable(dateNow);

				// --- Variants
				let variantsList = [];
				for (let variant of productData.productVariantsList) {
					let variantCreate = new VariantsCreate();
					for (let fieldName in variant) {
						if (
							variantManualFields.includes(fieldName) ||
							isUndefined(variantCreate[fieldSetter(fieldName)])
						)
							continue;

						variantCreate[fieldSetter(fieldName)](
							typeof variant[fieldName] === 'object' &&
								fieldName.includes('Price')
								? variant[fieldName].amount
								: variant[fieldName]
						);
					}

					if (!isEmpty(variant.variantSaleStart)) {
						let protoStart = new Int32Value();
						protoStart.setValue(
							typeof variant.variantSaleStart === 'object'
								? variant.variantSaleStart.value
								: variant.variantSaleStart
						);
						variantCreate.setVariantSaleStart(protoStart);
					}

					if (!isEmpty(variant.variantSaleEnd)) {
						let protoEnd = new Int32Value();
						protoEnd.setValue(
							typeof variant.variantSaleEnd === 'object'
								? variant.variantSaleEnd.value
								: variant.variantSaleEnd
						);
						variantCreate.setVariantSaleEnd(protoEnd);
					}

					// Variant stock internal info
					let stockInternalRequest = buildVariantStockInternalInfo(
						variant.stockInternalInfo
					);
					variantCreate.setStockInternalInfo(stockInternalRequest);

					// Variant Custom Fields Maps
					if (!isEmpty(variant.variantCustomFieldsMap)) {
						variant.variantCustomFieldsMap.forEach(([key, val]) =>
							variantCreate
								.getVariantCustomFieldsMap()
								.set(key, val)
						);
					}

					variantsList.push(variantCreate);
				}
				productCreateRequest.setProductVariantsList(variantsList);

				if (productCreateRequest.getProductVirtual()) {
					productCreateRequest.setProductWeight(Weight.W_V);
					productCreateRequest.setProductDimType(DIM.D_V);
				}

				// --- Custom fields
				if (!isEmpty(productData.productCustomFieldsMap))
					productData.productCustomFieldsMap.forEach(([key, val]) =>
						productCreateRequest
							.getProductCustomFieldsMap()
							.set(key, val)
					);

				// --- Check SEO fields
				if (isEmpty(productCreateRequest.getProductSeoUrl()))
					productCreateRequest.setProductSeoUrl(
						formatSeoUrl(productData.productName)
					);

				if (isEmpty(productCreateRequest.getProductSeoTitle()))
					productCreateRequest.setProductSeoTitle(
						formatSeoTitle(productData.productName)
					);

				if (isEmpty(productCreateRequest.getProductSeoDescription()))
					productCreateRequest.setProductSeoDescription(
						formatSeoDescription(productData.productDescription)
					);

				grpc.invoke(Products.CreateProduct, {
					request: productCreateRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		// use from category
		getProductList([listId, pageNumber, perPage]) {
			return new Promise((resolve, reject) => {
				const productListingInfo = new ProductListingInfo();

				productListingInfo.setLinkId(listId);
				if (!isEmpty(pageNumber)) productListingInfo.setPit(pageNumber);
				if (!isNaN(parseInt(perPage)) && parseInt(perPage) > 0)
					productListingInfo.setDisplayResult(perPage);

				grpc.invoke(Products.GetProductListingByID, {
					request: productListingInfo,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(
							message,
							resolve,
							reject,
							'productsListing'
						),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		// use from product (and so rule)
		getAllResources([
			page,
			ruleId,
			query = 'product.id!="0"',
			sortByList,
			perPage = 15,
		]) {
			//query = 'product.last.update <= timeAfter(0,0,3)'
			return new Promise((resolve, reject) => {
				const productListingInfo = new ProductListingInfo();

				resourceListingSet(
					productListingInfo,
					page,
					ruleId,
					query,
					sortByList,
					perPage
				);

				grpc.invoke(Products.GetProductListingByID, {
					request: productListingInfo,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		deleteResource(productId) {
			return new Promise((resolve, reject) => {
				const productInfoRequest = new ProductInfoRequest();

				productInfoRequest.setProductId(productId);

				grpc.invoke(Products.DeleteProduct, {
					request: productInfoRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		deleteVariant([productId, variantId]) {
			return new Promise((resolve, reject) => {
				const productVariant = new ProductVariant();

				productVariant.setProductId(productId);
				productVariant.setVariantId(variantId);

				grpc.invoke(Products.DeleteVariant, {
					request: productVariant,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},

					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		deleteVariantsByOptionsValues([productId, optionsDeleted]) {
			return new Promise((resolve, reject) => {
				const ProductVariantOptionDeleted =
					new ProductVariantOptionDelete();
				const repeatedOptions = [];
				ProductVariantOptionDeleted.setProductId(productId);

				optionsDeleted.forEach((option) => {
					const [key, val] = Object.entries(option)[0];

					let optionValue = new OptionValueObject();

					optionValue.getValueMap().set(key, val);
					repeatedOptions.push(optionValue);
				});
				ProductVariantOptionDeleted.setValueList(repeatedOptions);
				grpc.invoke(Products.DeleteProductVariantByOptionValue, {
					request: ProductVariantOptionDeleted,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},

					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		// This method delete group of variants
		deleteSliceOfVariants(productId, variants) {
			return new Promise((resolve, reject) => {
				const groupOfVariantsRequest = new ProductIDByVariantRequest();
				const repeatedProductVariants = [];
				variants.forEach((variant) => {
					const productVariant = new ProductVariant();
					productVariant.setProductId(productId);
					productVariant.setVariantId(variant.variantId);
					repeatedProductVariants.push(productVariant);
				});
				groupOfVariantsRequest.setProductVariantRequestList(
					repeatedProductVariants
				);

				grpc.invoke(Products.DeleteProductVariantBulk, {
					request: groupOfVariantsRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},

					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		ConfirmProductRuleExists(productVariant, queryId) {
			return new Promise((resolve, reject) => {
				const productRuleRequest = new ProductRuleRequest();

				const productVariantArray = [];

				productVariant.forEach((item) => {
					const productinfo = new ProductVariant();
					productinfo.setProductId(item.pid);
					productinfo.setVariantId(item.vid);
					productVariantArray.push(productinfo);
				});

				//  ProductVariant

				productRuleRequest.setProductVariantsList(productVariantArray);
				productRuleRequest.setRuleQuery(queryId);

				grpc.invoke(Products.ConfrimProductRuleExists, {
					request: productRuleRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		DeleteAllVariant([productId]) {
			return new Promise((resolve, reject) => {
				// var deletedProduct = productId
				const productVariant = new ProductVariant();

				productVariant.setProductId(productId);

				grpc.invoke(Products.DeleteAllVariant, {
					request: productVariant,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		productSearchOld(searchTerm) {
			return new Promise((resolve, reject) => {
				const productSearchRequest = new ProductSearchRequest();

				productSearchRequest.setSearchQuery(searchTerm);
				// productSearchRequest.setPageNum(pageNumber);

				grpc.invoke(Products.ProductSearch, {
					request: productSearchRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(
							message,
							resolve,
							reject,
							'productsListing'
						),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		searchResources([searchTerm, pageNumber]) {
			return new Promise((resolve, reject) => {
				const productSearchRequest = new ProductSearchRequest();

				productSearchRequest.setSearchQuery(searchTerm);
				productSearchRequest.setPageNum(pageNumber);

				grpc.invoke(Products.ProductSearch, {
					request: productSearchRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		productSearchableVariables([listFields, afterValue, pageNum]) {
			return new Promise((resolve, reject) => {
				const productSearchRequest = new ProductSearchRequest();

				productSearchRequest.setProductsSearchableFieldsList(
					listFields
				);
				if (afterValue) productSearchRequest.setAfterValue(afterValue);
				if (pageNum) productSearchRequest.setPageNum(pageNum);
				grpc.invoke(Products.ProductSearchableVariables, {
					request: productSearchRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		createRule([dataRule]) {
			return new Promise((resolve, reject) => {
				const productRuleRequest = new ProductRuleRequest();

				let dataList = {
					sortBy: 'setSortByList',
					ruleName: 'setRuleName',
					ruleQuery: 'setRuleQuery',
					associatedId: 'setCategoryId',
				};
				if (
					typeof dataRule.associatedId != 'undefined' &&
					!isNaN(dataRule.associatedId)
				) {
					dataRule.associatedId =
						dataRule.associatedId == 0
							? ''
							: String(dataRule.associatedId);
				}

				for (let keys in dataList) {
					if (typeof dataRule[keys] === 'undefined') continue;

					if (dataList[keys] == 'setSortByList') {
						let sortList = [];
						dataRule[keys].forEach((sortField) => {
							let sortBy = new RuleSortBy();
							sortBy.setColumnName(sortField.fieldName);
							sortBy.setSortOrder(sortField.sortOrder);
							sortList.push(sortBy);
						});
						productRuleRequest[dataList[keys]](sortList);
					} else {
						productRuleRequest[dataList[keys]](dataRule[keys]);
					}
				}
				grpc.invoke(Products.CreateProductRule, {
					request: productRuleRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		getListRules() {
			return new Promise((resolve, reject) => {
				grpc.invoke(Products.GetProductRule, {
					request: new ProductEmptyRequest(),
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(
							message,
							resolve,
							reject,
							'rulesList'
						),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		deleteRule([ruleId]) {
			return new Promise((resolve, reject) => {
				const productRuleRequest = new ProductRuleRequest();
				productRuleRequest.setRuleId(ruleId);

				grpc.invoke(Products.DeleteProductRule, {
					request: productRuleRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},

					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		updateVariantSale(data) {
			return new Promise((resolve, reject) => {
				const variantSaleRequest = new ProductVariantSaleRequest();
				variantSaleRequest.setProductId(data.productId);
				variantSaleRequest.setVariantId(data.variantId);

				data.fields.forEach((item) => {
					let fnName =
						'set' +
						item[0].charAt(0).toUpperCase() +
						item[0].slice(1);
					if (typeof variantSaleRequest[fnName] != 'undefined') {
						if (
							['variantSaleStart', 'variantSaleEnd'].includes(
								item[0]
							)
						) {
							let protoValue = new StringValue();
							protoValue.setValue(item[1]);
							variantSaleRequest[fnName](protoValue);
						} else {
							variantSaleRequest[fnName](item[1]);
						}
					}
				});

				grpc.invoke(Products.SetProductVariantSale, {
					request: variantSaleRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},

					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		updateVariantLocationsQty(data) {
			return new Promise((resolve, reject) => {
				const variantAvailableInventory =
					new VariantAvailableInventory();

				let productIdProto = new StringValue();
				productIdProto.setValue(data.productId);
				variantAvailableInventory.setProductId(productIdProto);

				let variantIdProto = new StringValue();
				variantIdProto.setValue(data.variantId);
				variantAvailableInventory.setVariantId(variantIdProto);

				let locationsList = [];
				data.variantLocationQtyList.forEach((loc) => {
					if (!isNaN(parseInt(loc.variantQty))) {
						let locationQty = new VariantLocationQty();
						locationQty.setLocationId(loc.locationId);
						locationQty.setVariantQty(parseInt(loc.variantQty));
						locationsList.push(locationQty);
					}
				});
				variantAvailableInventory.setVariantLocationQtyList(
					locationsList
				);

				grpc.invoke(Products.SetProductVariantAvailableInventory, {
					request: variantAvailableInventory,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},

					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		getProductCategories() {
			return new Promise((resolve, reject) => {
				const productsRuleRequest = new VariantAvailableInventory();

				grpc.invoke(Products.ProductsRuleList, {
					request: productsRuleRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},

					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		getRelatedProducts(productId) {
			return new Promise((resolve, reject) => {
				const productRequest = new ProductInfoRequest();
				productRequest.setProductId(productId);

				grpc.invoke(Products.GetRelatedProducts, {
					request: productRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		getInventoryLogs([productId, variantId, pageNum]) {
			return new Promise((resolve, reject) => {
				const inventoryLogRequest = new InventoryLogRequest();
				inventoryLogRequest.setProductId(productId);
				inventoryLogRequest.setVariantId(variantId);

				if (!isUndefined(pageNum))
					inventoryLogRequest.setPageNum(pageNum);

				grpc.invoke(Products.GetProductVariantHistory, {
					request: inventoryLogRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		getProductActions() {
			return new Promise((resolve, reject) => {
				const emptyRequest = new Empty();
				grpc.invoke(Products.GetProductActions, {
					request: emptyRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		setProductAction(actionData) {
			console.log('actionData', actionData);
			return new Promise((resolve, reject) => {
				const actionRequest = new ProductAction();
				if (!isEmpty(actionData.actionId))
					actionRequest.setActionId(actionData.actionId);

				actionRequest.setAction(actionData.action);
				actionRequest.setActionName(actionData.actionName);
				actionRequest.setActionRule(actionData.actionRule);
				actionRequest.setActionExpiry(actionData.actionExpiry);
				actionRequest.setActionFunction(actionData.actionFunction);

				let fieldsRequest = new Products[
					actionData.actionFunctionFormatted
				].requestType();

				// check first if the fieldsRequest is custom fields for custom handling

				if (
					actionData.actionFunctionFormatted !=
						'SetProductCustomFields' &&
					actionData.actionFunctionFormatted !=
						'SetProductVariantCustomFields'
				) {
					for (let fieldName in actionData.actionFields) {
						if (isUndefined(fieldsRequest[fieldSetter(fieldName)]))
							continue;
						fieldsRequest[fieldSetter(fieldName)](
							actionData.actionFields[fieldName]
						);
					}
				} else if (
					actionData.actionFunctionFormatted ==
						'SetProductCustomFields' ||
					actionData.actionFunctionFormatted ==
						'SetProductVariantCustomFields'
				) {
					// custom handling
					actionData.actionFields['valueMap'].forEach(([key, val]) =>
						fieldsRequest.getValueMap().set(key, val)
					);

					fieldsRequest[fieldSetter('appendData')](
						actionData.actionFields['appendData']
					);
				}

				actionRequest[fieldSetter(actionData.actionFieldsSetterName)](
					fieldsRequest
				);

				grpc.invoke(Products.SetProductActions, {
					request: actionRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
		deleteProductAction(actionData) {
			return new Promise((resolve, reject) => {
				const actionRequest = new ProductAction();
				actionRequest.setActionId(actionData.actionId);
				actionRequest.setAction(actionData.action);

				grpc.invoke(Products.DeleteProductActions, {
					request: actionRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},

		setProductQtyDiscount(productId, variantId, qtyDiscountList) {
			return new Promise((resolve, reject) => {
				const variantRequest = new ProductVariantQtyDiscount();
				variantRequest.setProductId(productId);
				variantRequest.setVariantId(variantId);

				qtyDiscountList.forEach((discount) => {
					let qtyDiscount = new QtyDiscount();
					qtyDiscount.setVariantQty(discount.variantQty);

					for (let fieldName of [
						'variantPrice',
						'variantDiscountPrice',
						'variantWholesalePrice',
						'variantWholesaleDiscountPrice',
					]) {
						if (isUndefined(discount[fieldName])) continue;
						let money = new Money();
						money.setAmount(
							typeof discount[fieldName] === 'object'
								? discount[fieldName].amount
								: discount[fieldName]
						);
						money.setBinaryOperator(BINARY_OPERATOR.ADD);
						money.setCurrencyCode(getStoreCurrency());
						qtyDiscount[fieldSetter(fieldName)](money);
					}

					if (!isEmpty(discount.customRule)) {
						let ruleProto = new StringValue();
						ruleProto.setValue(discount.customRule);
						qtyDiscount.setCustomRule(ruleProto);
					}

					if (!isEmpty(discount.groupTag)) {
						let groupTagProto = new StringValue();
						groupTagProto.setValue(discount.groupTag);
						qtyDiscount.setGroupTag(groupTagProto);
					}

					variantRequest.addQtyDiscount(qtyDiscount);
				});

				grpc.invoke(Products.SetProductVariantDiscountQty, {
					request: variantRequest,
					host: import.meta.env.VITE_API_URL,
					metadata: {
						'x-client-id': useClientsStore().getSelectedStore,
					},
					onMessage: (message) =>
						onMessageResponseAsync(message, resolve, reject),
					onEnd: (code, msg, trailers) =>
						onEndResponseAsync(
							code,
							msg,
							trailers,
							resolve,
							reject
						),
				});
			});
		},
	},
	getters: {
		getProductsSetters() {
			return new ProductInfoRequest().toObject();
		},

		getProductsCreateSetters() {
			const productFields = new ProductCreateRequest().toObject();

			productFields.productWeight = Weight.LBS;
			productFields.productDimType = DIM.IN;
			productFields.productOrderMin = 1;
			productFields.productOrderUnits = 1;
			productFields.productTaxed = true;
			productFields.relatedProducts = [];
			productFields.productChannelsList.push(
				PRODUCT_CHANNEL.ONLINE_STORE
			);
			return productFields;
		},

		getVariantsSetter() {
			const variantFields = new VariantsCreate().toObject();
			variantFields.variantStatus = true;
			variantFields.variantLimitedQty = true;
			variantFields.stockInternalInfo =
				this.getVariantStockInternalInfoSetter;
			return variantFields;
		},

		getVariantStockInternalInfoSetter() {
			let stockInternalInfo = new StockInternalInfo().toObject();
			stockInternalInfo.cost = new Money().toObject();
			stockInternalInfo.msrp = new Money().toObject();
			return stockInternalInfo;
		},
		getProductActionSetter() {
			let actionFields = new ProductAction().toObject();
			actionFields.actionExpiry = '';
			return actionFields;
		},
		getRuleFieldsSetter() {
			const productRuleFields = Object.assign(
				this.getProductsSetters,
				this.getVariantsSetter
			);
			return productRuleFields;
		},
		getQtyDiscountSetter() {
			return new QtyDiscount().toObject();
		},
	},
});
