<template>
	<div
		class="modal fade"
		role="dialog"
		tabindex="-1"
		:class="showData === true ? 'show' : ''"
		:aria-labelledby="`modal-title-${id}`"
		aria-modal="true"
		@keyup.esc="close()"
	>
		<div
			class="modal-dialog"
			:class="`modal-${size} ${customClass}`"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" :id="`modal-title-${id}`">
						<ElIcon
							icon="warning"
							v-if="type == 'warning'"
							class="d-inline me-2"
						/>
						{{ title }}
					</h5>
					<ElButton
						class="btn-close"
						data-dismiss="modal"
						aria-label="Close"
						@click="close()"
					>
						<ElIcon
							icon="cross"
							text="Close"
							:tooltip="undefined"
						/>
					</ElButton>
				</div>
				<div class="modal-body">
					<slot></slot>
				</div>
				<div class="modal-footer" v-if="hasFooter">
					<slot name="footer">
						<ElButton
							class="me-auto"
							:class="customizeButtonAtLeftClass"
							data-dismiss="modal"
							@click="$emit('modal-click-dedicated-button')"
							v-if="customizeButtonAtLeft"
						>
							{{ customizeButtonAtLeftText }}
						</ElButton>
						<ElButton
							class="btn-light"
							data-dismiss="modal"
							@click="close()"
						>
							{{ buttonCloseText }}
						</ElButton>

						<ElButton
							v-if="enableButtonRemove"
							class="btn-danger"
							:class="buttonRemoveClass"
							@click="removed()"
						>
							{{ buttonRemoveText }}
						</ElButton>

						<ElButton
							v-if="!hideButtonConfirm"
							class="confirm"
							:class="buttonConfirmClass"
							:disabled="disableButtonConfirm"
							@click="confirm()"
						>
							<ElSpinner v-if="confirmProcessing" class="me-2" />
							{{ buttonConfirmText }}
						</ElButton>
					</slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import i18n from '@/lang';
	import ElSpinner from '@/components/ui/ElSpinner.vue';
	export default {
		components: { ElSpinner },
		props: {
			title: {
				type: String,
				default() {
					return '';
				},
			},
			customizeButtonAtLeftClass: {
				type: String,
				default: 'btn-light',
			},
			show: {
				type: Boolean,
				default: false,
			},
			hideButtonConfirm: {
				type: Boolean,
				default: false,
			},
			disableButtonConfirm: {
				type: Boolean,
				default: false,
			},
			enableButtonRemove: {
				type: Boolean,
				default: false,
			},
			hasFooter: {
				type: Boolean,
				default: true,
			},
			buttonConfirmText: {
				type: String,
				default() {
					return i18n.global.t('button.save_changes');
				},
			},
			buttonConfirmClass: {
				type: String,
				default: 'btn-primary',
			},
			confirmProcessing: {
				type: Boolean,
				default: false,
			},
			buttonRemoveText: {
				type: String,
				default() {
					return i18n.global.t('button.remove_changes');
				},
			},
			buttonRemoveClass: {
				type: String,
				default: 'btn-primary',
			},
			buttonCloseText: {
				type: String,
				default() {
					return i18n.global.t('button.cancel');
				},
			},
			size: {
				type: String,
				default: 'md',
			},
			keepModalOpenOnConfirm: {
				type: Boolean,
				default: false,
			},
			customClass: {
				type: String,
				default: '',
			},
			stopHide: {
				type: Boolean,
				default: false,
			},
			customizeButtonAtLeft: {
				type: Boolean,
				default: false,
			},
			customizeButtonAtLeftText: {
				type: String,
				default: '',
			},
			type: {
				type: String,
				default: '',
			},
		},
		emits: [
			'modal-cancel',
			'modal-confirmation',
			'update:show',
			'modal-removed-data',
			'modal-click-dedicated-button',
		],
		data() {
			return {
				showData: this.show,
				id: this._.uid,
			};
		},

		watch: {
			show(newVal, oldVal) {
				this.showData = newVal !== oldVal ? newVal : oldVal;
			},
		},

		methods: {
			close() {
				if (!this.stopHide) this.hide();
				this.$emit('modal-cancel');
			},

			confirm() {
				if (!this.keepModalOpenOnConfirm) this.hide();
				this.$emit('modal-confirmation');
			},

			hide() {
				this.showData = false;
				this.$emit('update:show', false);
			},

			removed() {
				this.hide();
				this.$emit('modal-removed-data');
			},
		},

		/**
		 * Focus component when it mounts to make
		 * keyboard esc click close modal
		 */
		mounted() {
			this.$el.focus();
		},
	};
</script>

<style lang="scss"></style>
