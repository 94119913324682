import { createPopper } from '@popperjs/core';

let popperInstance = null;

export const popper = {
	props: {
		placement: {
			type: String,
			default: 'bottom-start',
		},
		/*
		 *
		 * Determines strategy used to position popover. Set to "fixed" in template editor as a fix
		 * for the editor's tabs context menu (issues with the overflow-x: scroll on the tabs section)
		 *
		 */
		strategy: {
			type: String,
			default: 'absolute',
		},
		/*
		 *
		 * Forces popper to remain in set position if set to false
		 *
		 */
		flip: {
			type: Boolean,
			default: true,
		},
	},

	emits: ['shown', 'hidden'],

	data() {
		return {
			id: this._.uid,
			isPopperExpanded: false,
		};
	},

	methods: {
		showPopper() {
			if (this.isPopperExpanded) return false;

			this.isPopperExpanded = true;
			this.createPopper();
			this.$emit('shown');
		},

		createPopper() {
			this.$nextTick(() => {
				let referenceElement = this.$refs.referenceElement;
				let popperElement = this.$refs.popperElement;

				if (referenceElement._isVue) {
					referenceElement = referenceElement.$el;
				}

				popperInstance = createPopper(referenceElement, popperElement, {
					placement: this.placement,
					strategy: this.strategy,
					modifiers: [
						{
							name: 'flip',
							enabled: this.flip,
						},
					],
				});
			});
		},

		hidePopper() {
			if (!this.isPopperExpanded) return false;

			this.isPopperExpanded = false;
			this.destroyPopper();
			this.$emit('hidden');
		},

		destroyPopper() {
			if (popperInstance) {
				popperInstance.destroy();
				popperInstance = null;
			}
		},

		onBlur() {
			setTimeout(() => this.hidePopper(), 300);
		},

		onClickOutside(event) {
			if (this.$el.contains(event.target)) return;
			// console.log(this);
			this.hidePopper();
		},
	},

	mounted() {
		document.addEventListener('click', this.onClickOutside);
	},

	unmounted() {
		document.removeEventListener('click', this.onClickOutside);
	},
};
