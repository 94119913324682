<template>
	<img class="t-image" :src="url" @error="setAltImg($event)" />
</template>

<script>
	export default {
		props: {
			src: {
				type: String,
				default: '',
			},
			alt: {
				type: String,
				default: '',
			},
			loading: {
				type: String,
				default: 'lazy', // or auto
			},
		},

		data() {
			return {
				altImg: this.$asset().url('/img/default-product-img.png'),
			};
		},

		computed: {
			/**
			 * if src is '', the
			 * for avoiding calling setAltImg
			 */
			url() {
				let url = this.src;

				// if(values.length)
				if (this.src === '') url = this.altImg;

				return url;
			},
		},

		methods: {
			/**
			 * If src is a broken link, set an alternative
			 * image
			 */
			setAltImg(e) {
				e.target.src = this.altImg;
			},
		},
	};
</script>
