<template>
	<label
		class="form-label"
		:class="{ 'with-tooltip': !$_.isEmpty(tooltip), [selector]: true }"
		v-bind="$attrs"
	>
		<slot></slot>
	</label>
	<ElTooltip
		v-if="!$_.isEmpty(tooltip)"
		placement="bottom-end"
		strategy="fixed"
		:trigger-el-selector="`.${selector}`"
		style="width: 200px"
	>
		{{ tooltip }}
	</ElTooltip>
</template>

<script>
	import ElTooltip from '@/components/ui/ElTooltip.vue';
	export default {
		components: { ElTooltip },
		props: {
			tooltip: {
				type: String,
				default: '',
			},
		},
		computed: {
			selector() {
				return `el_${this._.uid}`;
			},
		},
	};
</script>

<style scoped>
	label.with-tooltip::after {
		content: ' ';
		background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjE2MCIgd2lkdGg9IjE2MCIgdmVyc2lvbj0iMS4wIj4KIDxnIGZpbGw9ImN1cnJlbnRDb2xvciI+CiAgPHBhdGggZD0ibTgwIDE1Yy0zNS44OCAwLTY1IDI5LjEyLTY1IDY1czI5LjEyIDY1IDY1IDY1IDY1LTI5LjEyIDY1LTY1LTI5LjEyLTY1LTY1LTY1em0wIDEwYzMwLjM2IDAgNTUgMjQuNjQgNTUgNTVzLTI0LjY0IDU1LTU1IDU1LTU1LTI0LjY0LTU1LTU1IDI0LjY0LTU1IDU1LTU1eiIvPgogIDxwYXRoIGQ9Im01Ny4zNzMgMTguMjMxYTkuMzgzNCA5LjExNTMgMCAxIDEgLTE4Ljc2NyAwIDkuMzgzNCA5LjExNTMgMCAxIDEgMTguNzY3IDB6IiB0cmFuc2Zvcm09Im1hdHJpeCgxLjE5ODkgMCAwIDEuMjM0MiAyMS4yMTQgMjguNzUpIi8+CiAgPHBhdGggZD0ibTkwLjY2NSAxMTAuOTZjLTAuMDY5IDIuNzMgMS4yMTEgMy41IDQuMzI3IDMuODJsNS4wMDggMC4xdjUuMTJoLTM5LjA3M3YtNS4xMmw1LjUwMy0wLjFjMy4yOTEtMC4xIDQuMDgyLTEuMzggNC4zMjctMy44MnYtMzAuODEzYzAuMDM1LTQuODc5LTYuMjk2LTQuMTEzLTEwLjc1Ny0zLjk2OHYtNS4wNzRsMzAuNjY1LTEuMTA1Ii8+CiA8L2c+Cjwvc3ZnPgo=);
		background-size: cover;
		display: inline-block;
		width: 14px;
		height: 14px;
		cursor: pointer;
		margin-left: 3px;
		margin-right: 3px;
	}
</style>
