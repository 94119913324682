import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useClientsStore } from '@/stores/clients';

const loginNotRequiredPages = [
	'404',
	'login',
	'registration',
	'recovery',
	'auth_error',
	'verify',
	'logout',
	'oauth_login',
];

const routes = [
	// -- 404
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		components: {
			default: () => import('@/views/errors/PageNotFound.vue'),
		},
	},
	// -- Dashboard
	{
		path: '/',
		name: 'homepage',
		components: {
			default: () => import('@/views/homepage/HomeDashboard.vue'),
		},
	},
	// -- Products
	{
		path: '/products',
		children: [
			{
				path: '/products',
				name: 'products_index',
				components: {
					default: () => import('@/views/products/ProductsIndex.vue'),
				},
			},
			{
				path: '/products/edit/:ids',
				name: 'products_bulk_edit',
				components: {
					default: () =>
						import('@/views/products/ProductsBulkEdition.vue'),
				},
			},

			{
				path: '/products/:id([-\\d\\w]+)/variants/:variantId([-\\d\\w]+)?',
				name: 'products_variants_show',
				components: {
					default: () =>
						import(
							'@/views/products/ProductsVariantsEditAndShow.vue'
						),
				},
			},
			{
				path: '/products/new',
				name: 'products_new',
				components: {
					default: () => import('@/views/products/ProductsNew.vue'),
				},
			},
			// {
			// 	path: 'import',
			// 	name: 'products_import',
			// 	components: {
			// 		default: () => import('@/views/products/ProductsImport.vue'),
			// 	},
			// },
			{
				path: '/products/bulk/update',
				name: 'products_bulk_update',
				components: {
					default: () =>
						import('@/views/products/ProductsBulkUpdate.vue'),
				},
			},
			{
				path: '/products/actions',
				name: 'products_actions_index',
				components: {
					default: () =>
						import('@/views/products/ProductsActionsIndex.vue'),
				},
			},
			{
				path: '/products/:id([-\\d\\w]+)',
				name: 'products_show',
				components: {
					default: () =>
						import('@/views/products/ProductsEditAndShow.vue'),
				},
			},
		],
	},
	// -- Customers
	{
		path: '/customers',
		name: 'customers_index',
		components: {
			default: () => import('@/views/customers/CustomersIndex.vue'),
		},
	},
	{
		path: '/customers/new',
		name: 'customers_new',
		components: {
			default: () => import('@/views/customers/CustomersNew.vue'),
		},
	},
	{
		path: '/customers/:id([-\\d\\w]+)',
		name: 'customers_show',
		components: {
			default: () => import('@/views/customers/CustomersEditAndShow.vue'),
		},
	},
	// -- Orders
	{
		path: '/orders',
		children: [
			{
				path: '/orders/draft',
				name: 'draft_orders_index',
				components: {
					default: () =>
						import('@/views/orders/DraftOrdersIndex.vue'),
				},
			},
			{
				path: '/orders/draft/new/:cartUuid?',
				name: 'draft_orders_new',
				components: {
					default: () => import('@/views/orders/OrdersNew.vue'),
				},
			},
			{
				path: '/orders/draft/:id([-\\d\\w]+)',
				name: 'draft_orders_show',
				components: {
					default: () =>
						import('@/views/orders/OrdersEditAndShow.vue'),
				},
			},

			{
				path: '/orders/abandoned',
				name: 'abandoned_orders_index',
				components: {
					default: () =>
						import('@/views/orders/AbandonedOrdersIndex.vue'),
				},
			},
			{
				path: '/orders',
				name: 'orders_index',
				components: {
					default: () => import('@/views/orders/OrdersIndex.vue'),
				},
			},
			{
				path: '/orders/packingslip',
				name: 'orders_packing_slip_template',
				components: {
					default: () =>
						import(
							'@/views/orders/components/OrdersPackingSlipTemplate.vue'
						),
				},
			},
			{
				path: '/orders/:id([-\\d\\w]+)/:filter(all|draft|abandoned)?',
				name: 'orders_show',
				components: {
					default: () =>
						import('@/views/orders/OrdersEditAndShow.vue'),
				},
			},
			{
				path: '/orders/:id([-\\d\\w]+)/refund',
				name: 'orders_refund',
				components: {
					default: () => import('@/views/orders/OrdersRefund.vue'),
				},
			},
			{
				path: '/orders/:id([-\\d\\w]+)/fulfill',
				name: 'orders_fulfill',
				components: {
					default: () =>
						import('@/views/orders/OrdersFulfillment.vue'),
				},
			},
			{
				path: '/orders/modules/scripts',
				name: 'orders_module_script_index',
				components: {
					default: () =>
						import('@/views/orders/OrdersModuleScriptIndex.vue'),
				},
			},
			{
				path: '/orders/modules/scripts/new',
				name: 'orders_module_script_new',
				components: {
					default: () =>
						import('@/views/orders/OrdersModuleScriptNew.vue'),
				},
			},
			{
				path: '/orders/modules/script/playground',
				name: 'orders_module_playground',
				components: {
					default: () =>
						import(
							'@/views/orders/OrdersModuleScriptPlayground.vue'
						),
				},
			},
			{
				path: '/orders/modules/scripts/:id([-\\d\\w]+)',
				name: 'orders_module_script_show',
				components: {
					default: () =>
						import(
							'@/views/orders/OrdersModuleScriptEditAndShow.vue'
						),
				},
			},
		],
	},
	// -- Discounts
	{
		path: '/discounts',
		name: 'discounts_index',
		components: {
			default: () => import('@/views/discounts/DiscountsIndex.vue'),
		},
	},
	{
		path: '/discounts/auto',
		name: 'discounts_automatic_index',
		components: {
			default: () =>
				import('@/views/discounts/DiscountsAutomaticIndex.vue'),
		},
	},
	{
		path: '/discounts/new/:type(code|auto)',
		name: 'discounts_new',
		components: {
			default: () => import('@/views/discounts/DiscountsNew.vue'),
		},
	},
	{
		path: '/discounts/:id',
		name: 'discounts_show',
		components: {
			default: () => import('@/views/discounts/DiscountsEditAndShow.vue'),
		},
	},
	// -- Categories
	{
		path: '/categories',
		name: 'categories_index',
		components: {
			default: () => import('@/views/categories/CategoriesIndex.vue'),
		},
	},
	{
		path: '/categories/new',
		name: 'categories_new',
		components: {
			default: () => import('@/views/categories/CategoriesNew.vue'),
		},
	},
	{
		path: '/categories/:id([-\\d\\w]+)',
		name: 'categories_show',
		components: {
			default: () =>
				import('@/views/categories/CategoriesEditAndShow.vue'),
		},
	},
	// -- Store credit
	{
		path: '/credits',
		children: [
			{
				path: '/credits/new',
				name: 'credits_new',
				components: {
					default: () => import('@/views/credit/StoreCreditNew.vue'),
				},
			},
			{
				path: '/credits/ledger/new',
				name: 'credits_ledger_new',
				components: {
					default: () =>
						import('@/views/credit/StoreCreditLedgerNew.vue'),
				},
			},
			{
				path: '/credits',
				name: 'credits_index',
				components: {
					default: () =>
						import('@/views/credit/StoreCreditIndex.vue'),
				},
			},
			{
				path: '/credits/ledger/customer/:customerId([-\\d\\w]+)?',
				name: 'ledger_index_customer',
				components: {
					default: () =>
						import('@/views/credit/StoreCreditLedgerIndex.vue'),
				},
			},
			{
				path: '/credits/ledger',
				name: 'ledger_index',
				components: {
					default: () =>
						import('@/views/credit/StoreCreditLedgerIndex.vue'),
				},
			},
			{
				path: '/credits/gift_card/ledger',
				name: 'gift_card_ledger_index',
				components: {
					default: () =>
						import(
							'@/views/credit/StoreCreditGiftCardLedgerIndex.vue'
						),
				},
			},
			{
				path: '/credits/:id([-\\d\\w]+)',
				name: 'credits_show',
				components: {
					default: () =>
						import('@/views/credit/StoreCreditEditAndShow.vue'),
				},
			},
			{
				path: '/credits/ledger/:id([-\\d\\w]+)',
				name: 'ledgers_show',
				components: {
					default: () =>
						import(
							'@/views/credit/StoreCreditLedgerEditAndShow.vue'
						),
				},
			},
		],
	},
	// -- Plugins
	{
		path: '/plugins',
		name: 'plugins',
		components: {
			default: () => import('@/views/plugins/PluginsIndex.vue'),
		},
	},
	{
		path: '/plugins/:id([-\\d\\w]+)',
		name: 'plugins_show',
		components: {
			default: () => import('@/views/plugins/PluginsShow.vue'),
		},
	},
	// -- Shipping label
	{
		path: '/shipping_labels/purchase/:ordersIds([-\\d\\w\\,]+)',
		name: 'labels_new',
		components: {
			default: () => import('@/views/labels/ShippingLabelNew.vue'),
		},
	},
	// -- Settings
	{
		path: '/admin',
		name: 'admin',
		components: {
			// default: () => import('@/views/admin/AdminMenuBuilder.vue')
		},
	},

	{
		path: '/admin/shipping',
		name: 'admin_shipping_index',
		components: {
			default: () =>
				import('@/views/admin/shipping/AdminShippingIndex.vue'),
		},
	},
	{
		path: '/admin/shipping/external/plugins',
		name: 'shippings_plugins_external',
		components: {
			default: () =>
				import(
					'@/views/admin/shipping/AdminShippingMethodExternalPlugins.vue'
				),
		},
	},
	{
		path: '/admin/shipping/playground',
		name: 'shippings_playground',
		components: {
			default: () =>
				import('@/views/admin/shipping/AdminShippingPlayground.vue'),
		},
	},
	{
		path: '/admin/shipping/new',
		name: 'shippings_new',
		components: {
			default: () =>
				import('@/views/admin/shipping/AdminShippingMethodNew.vue'),
		},
	},
	{
		path: '/admin/shipping/:id',
		name: 'shippings_show',
		components: {
			default: () =>
				import('@/views/admin/shipping/AdminShippingMethodEdit.vue'),
		},
	},
	{
		path: '/admin/payments',
		name: 'admin_payments_index',
		components: {
			default: () =>
				import('@/views/admin/payments/AdminPaymentsIndex.vue'),
		},
	},
	{
		path: '/admin/payments/:id',
		name: 'admin_payments_config',
		components: {
			default: () =>
				import('@/views/admin/payments/AdminPaymentsEdit.vue'),
		},
	},
	{
		path: '/admin/settings',
		name: 'settings',
		components: {
			default: () => import('@/views/admin/SettingsIndex.vue'),
		},
	},
	{
		path: '/admin/general',
		name: 'admin_general',
		components: {
			default: () => import('@/views/admin/GeneralSettings.vue'),
		},
	},
	{
		path: '/admin/legal',
		name: 'admin_legal',
		components: {
			default: () => import('@/views/admin/LegalSettings.vue'),
		},
	},
	{
		path: '/admin/checkout',
		name: 'admin_checkout',
		components: {
			default: () => import('@/views/admin/CheckoutSettings.vue'),
		},
	},
	{
		path: '/admin/tax',
		name: 'taxes_index',
		components: {
			default: () => import('@/views/admin/tax/AdminTaxIndex.vue'),
		},
	},
	{
		path: '/admin/tax/:id',
		name: 'taxes_show',
		components: {
			default: () => import('@/views/admin/tax/AdminTaxEditAndShow.vue'),
		},
	},
	{
		path: '/admin/locations',
		name: 'locations_index',
		components: {
			default: () =>
				import('@/views/admin/locations/AdminLocationsIndex.vue'),
		},
	},
	{
		path: '/admin/locations/new',
		name: 'locations_new',
		components: {
			default: () =>
				import('@/views/admin/locations/AdminLocationsNew.vue'),
		},
	},
	{
		path: '/admin/locations/:id',
		name: 'locations_show',
		components: {
			default: () =>
				import('@/views/admin/locations/AdminLocationsEditAndShow.vue'),
		},
	},
	{
		path: '/admin/analytics',
		name: 'analytics_index',
		components: {
			default: () =>
				import('@/views/admin/integrations/GoogleAnalyticsIndex.vue'),
		},
	},
	{
		path: '/admin/redirects',
		name: 'redirects_index',
		components: {
			default: () =>
				import('@/views/admin/redirects/AdminRedirectsIndex.vue'),
		},
	},
	{
		path: '/admin/blocklist',
		name: 'blocklist_index',
		components: {
			default: () =>
				import('@/views/admin/blocklist/AdminBlocklistIndex.vue'),
		},
	},
	{
		path: '/admin/accounts/groups',
		name: 'admin_accounts_groups',
		components: {
			default: () =>
				import('@/views/admin/accounts/AdminAccountsGroups.vue'),
		},
	},
	{
		path: '/admin/accounts',
		name: 'admin_accounts',
		components: {
			default: () =>
				import('@/views/admin/accounts/AdminAccountsIndex.vue'),
		},
	},
	// -- Storefront
	{
		path: '/storefront',
		children: [
			{
				path: '/storefront',
				name: 'storefront',
				redirect: '/storefront/templates',
			},
			{
				path: '/storefront/templates',
				name: 'templates_index',
				components: {
					default: () =>
						import('@/views/templates/TemplatesIndex.vue'),
				},
			},
			{
				path: '/storefront/templates/new',
				name: 'templates_new',
				components: {
					default: () => import('@/views/templates/TemplatesNew.vue'),
				},
			},
			{
				path: '/storefront/templates/:name',
				name: 'templates_edit_files',
				components: {
					default: () =>
						import('@/views/templates/TemplatesEditFiles.vue'),
				},
			},
			{
				path: '/storefront/templates/:name/configuration',
				name: 'templates_configuration',
				components: {
					default: () =>
						import('@/views/templates/TemplatesConfiguration.vue'),
				},
				meta: { hideHeader: true, hideSideNav: true },
			},
			{
				path: '/storefront/banners',
				name: 'banners_index',
				components: {
					default: () => import('@/views/banners/BannersIndex.vue'),
				},
			},
			{
				path: '/storefront/banners/new',
				name: 'banners_new',
				components: {
					default: () => import('@/views/banners/BannersNew.vue'),
				},
			},
			{
				path: '/storefront/banners/:id',
				name: 'banners_show',
				components: {
					default: () =>
						import('@/views/banners/BannersEditAndShow.vue'),
				},
			},
			{
				path: '/storefront/banners/builder/:id',
				name: 'banners_content_builder',
				components: {
					default: () =>
						import('@/views/banners/BannersContentBuilder.vue'),
				},
			},
			{
				path: '/storefront/pages',
				name: 'pages_index',
				components: {
					default: () => import('@/views/pages/PagesIndex.vue'),
				},
			},
			{
				path: '/storefront/pages/new',
				name: 'pages_new',
				components: {
					default: () => import('@/views/pages/PagesNew.vue'),
				},
			},
			{
				path: '/storefront/pages/:id',
				name: 'pages_show',
				components: {
					default: () => import('@/views/pages/PagesEditAndShow.vue'),
				},
			},
			{
				path: '/storefront/menus',
				name: 'menus_index',
				components: {
					default: () => import('@/views/menus/MenusIndex.vue'),
				},
			},
			{
				path: '/storefront/menus/new',
				name: 'menus_new',
				components: {
					default: () => import('@/views/menus/MenusNew.vue'),
				},
			},
			{
				path: '/storefront/menus/:id([-\\d\\w]+)',
				name: 'menus_show',
				components: {
					default: () => import('@/views/menus/MenusEditAndShow.vue'),
				},
			},
		],
	},
	// -- Blogs
	{
		path: '/blogs',
		children: [
			{
				path: '/blogs/posts',
				name: 'posts_index',
				components: {
					default: () => import('@/views/blogs/BlogsPostsIndex.vue'),
				},
			},
			{
				path: '/blogs/posts/new',
				name: 'posts_new',
				components: {
					default: () => import('@/views/blogs/BlogsPostsNew.vue'),
				},
			},
			{
				path: '/blogs/posts/:id',
				name: 'posts_show',
				components: {
					default: () =>
						import('@/views/blogs/BlogsPostsEditAndShow.vue'),
				},
			},
			{
				path: '/blogs',
				name: 'blogs_index',
				components: {
					default: () => import('@/views/blogs/BlogsIndex.vue'),
				},
			},
			{
				path: '/blogs/new',
				name: 'blogs_new',
				components: {
					default: () => import('@/views/blogs/BlogsNew.vue'),
				},
			},
			{
				path: '/blogs/:id',
				name: 'blogs_show',
				components: {
					default: () => import('@/views/blogs/BlogsEditAndShow.vue'),
				},
			},
		],
	},
	// -- Reports
	{
		path: '/reports',
		children: [
			{
				path: '/reports',
				name: 'reports',
				redirect: '/reports/customer',
			},
			{
				path: '/reports/customer',
				name: 'reports_customer_orders',
				components: {
					default: () =>
						import('@/views/reports/ReportsCustomerOrders.vue'),
				},
			},
			{
				path: '/reports/tax',
				name: 'reports_sales_tax',
				components: {
					default: () =>
						import('@/views/reports/ReportsSalesIndex.vue'),
				},
			},
		],
	},
	// -- Images
	{
		path: '/images',
		name: 'images_index',
		components: {
			default: () => import('@/views/images/ImageLibraryIndex.vue'),
		},
	},
	// -- Authentication & Account
	{
		path: '/oauth/login',
		name: 'oauth_login',
		components: {
			default: () => import('@/views/auth/OAuthLogin.vue'),
		},
		meta: { hideHeader: true, hideSideNav: true },
	},
	{
		path: '/oauth/consent',
		name: 'oauth_consent',
		components: {
			default: () => import('@/views/auth/OAuthConsent.vue'),
		},
		meta: { hideHeader: true, hideSideNav: true },
	},
	{
		path: '/login',
		name: 'login',
		components: {
			default: () => import('@/views/auth/AccountLogin.vue'),
		},
		meta: { hideHeader: true, hideSideNav: true },
	},
	{
		path: '/logout',
		name: 'logout',
		components: {
			default: () => import('@/views/auth/AccountLogout.vue'),
		},
	},
	{
		path: '/recovery',
		name: 'recovery',
		components: {
			default: () => import('@/views/auth/AccountRecovery.vue'),
		},
		meta: { hideHeader: true, hideSideNav: true },
	},
	{
		path: '/verify',
		name: 'verify',
		components: {
			default: () => import('@/views/auth/AccountVerification.vue'),
		},
	},
	{
		path: '/registration',
		name: 'registration',
		components: {
			default: () => import('@/views/auth/AccountRegistration.vue'),
		},
		meta: { hideHeader: true, hideSideNav: true },
	},
	{
		path: '/account',
		name: 'account',
		components: {
			default: () => import('@/views/auth/AccountIndex.vue'),
		},
	},
	{
		path: '/auth/error',
		name: 'auth_error',
		components: {
			default: () => import('@/views/auth/AuthError.vue'),
		},
		meta: { hideHeader: true, hideSideNav: true },
	},
	// -- Store setup and selection
	{
		path: '/store/setup',
		name: 'store_setup',
		components: {
			default: () => import('@/views/clients/StoreSetup.vue'),
		},
		meta: { hideSideNav: true },
	},
	// {
	// 	path: '/store/plan/selection',
	// 	name: 'store_plan_selection',
	// 	components: {
	// 		default: () => import('@/views/clients/StorePlanSelection.vue'),
	// 	},
	// 	meta: { hideSideNav: true },
	// },
	{
		path: '/store/selection',
		name: 'store_selection',
		components: {
			default: () => import('@/views/clients/StoreSelection.vue'),
		},
		meta: { hideSideNav: true },
	},
	{
		path: '/store/join/:token',
		name: 'store_join',
		components: {
			default: () => import('@/views/clients/StoreJoin.vue'),
		},
		meta: { hideSideNav: true },
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

const checkSession = function (to, from, next) {
	const clientsStore = useClientsStore();
	const authStore = useAuthStore();
	if (!loginNotRequiredPages.includes(to.name) && !authStore.isAuthenticated)
		// Login page uses query param return_to because ory will send url on it
		next({
			name: 'login',
			query: { return_to: window.location.origin + to.fullPath },
		});
	else if (
		['login', 'recovery'].includes(to.name) &&
		authStore.isAuthenticated
	)
		next({ name: 'account' });
	else if (
		!loginNotRequiredPages.includes(to.name) &&
		![
			'store_plan_selection',
			'store_setup',
			'store_selection',
			'store_join',
			'account',
		].includes(to.name) &&
		!clientsStore.getSelectedStore
	)
		next({ name: 'store_selection', query: { redirect_to: to.fullPath } });
	else {
		clientsStore.matchSubdomainWithSelectedStore(to.meta.newStore);
		next();
	}
};

router.beforeEach((to, from, next) => {
	const authStore = useAuthStore();
	if (!authStore.sessionValidated) {
		authStore.validateSession().finally(() => checkSession(to, from, next));
	} else checkSession(to, from, next);
});

export default router;
