<template>
	<span class="badge p-2" v-if="text">{{ text }}</span>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			required: true,
		},
	},
};
</script>

<style></style>
