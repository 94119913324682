export const select = {
	data() {
		return {
			highlightedIndex: -1,
			options: [], // TODO: give a better name
		};
	},

	watch: {
		highlightedIndex(newVal) {
			this.clearSelectedElement();
			this.selectElement(newVal);
		},
	},

	methods: {
		handleKeyDown(event) {
			let key = event.key;
			switch (key) {
				case 'Enter':
					this.onKeyEnter();
					event.preventDefault();
					break;
				case 'ArrowDown':
					this.setMenuIndex(this.highlightedIndex + 1);
					event.preventDefault();
					break;
				case 'ArrowUp':
					this.setMenuIndex(this.highlightedIndex - 1);
					event.preventDefault();
					break;
				case 'Tab':
					if (this.onBlur) this.onBlur();
					break;
				case 'Escape':
					if (this.hidePopper) this.hidePopper();
					break;
			}
		},

		setMenuIndex(index) {
			if (this.isPopperExpanded && !this.isPopperExpanded) return;

			if (this.canSetIndex(index)) {
				if (index === 0) this.highlightedIndex = 0;
				if (index > 0) this.highlightedIndex = index;
			}
		},

		canSetIndex(index) {
			return (
				this.options.length && index > -1 && index < this.options.length
			);
		},

		selectElement(index) {
			if (index > -1) {
				let el = this.options[index];

				if (!el) return;

				this.controlElementAria(el, true);
				el.classList.add('is-focused');
				el.scrollIntoView({ block: 'nearest' });
			}
		},

		clearSelectedElement() {
			this.options.forEach((el) => {
				this.controlElementAria(el, false);
				el.classList.remove('is-focused');
			});
		},

		controlElementAria(el, value) {
			if (el && el.hasAttribute('aria-selected')) {
				el.setAttribute('aria-selected', String(value));
			}
		},

		setSelectableElements() {
			this.options = this.$el.querySelectorAll('.dropdown-item');
			this.$nextTick(() => this.selectElement(this.highlightedIndex));
		},

		resetHighlightedIndex() {
			this.highlightedIndex = -1;
		},

		resetPopperScrollPosition() {
			let popperElement = this.$refs.popperElement;
			if (popperElement) popperElement.scrollTop = 0;
		},
	},
};
