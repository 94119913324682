<template>
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb align-items-center mb-3">
			<li class="breadcrumb-item">
				<router-link
					class="btn btn-icon d-block"
					:to="{
						name: fromLink,
						params: fromParams,
						query: {
							usePreviousPit: true,
						},
					}"
				>
					<ElIcon icon="arrow-left" text="Back" />
				</router-link>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				<small>{{ fromTitle }}</small>
			</li>
		</ol>
	</nav>
</template>

<script>
	export default {
		props: {
			fromLink: {
				type: String,
				default: undefined,
			},
			fromTitle: {
				type: String,
				default: undefined,
			},
			fromParams: {
				type: Object,
				default() {
					return {};
				},
			},
		},
		computed: {
			router() {
				return this.$router.options.history;
			},
		},
	};
</script>

<style scoped lang="scss">
	nav .breadcrumb {
		line-height: 1;
	}
</style>
