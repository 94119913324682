<template>
	<div
		class="toast show"
		:class="`toast-${type}`"
		role="alert"
		aria-live="assertive"
		aria-atomic="true"
	>
		<div class="toast-body d-flex">
			<div class="flex-1 me-3" v-html="message"></div>
			<button
				aria-label="Close"
				class="btn btn-icon ms-auto"
				type="button"
				@click="hide($event)"
			>
				<ElIcon icon="cross" :tooltip="undefined" />
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			index: {
				type: [String, Number],
				required: true,
			},
			duration: {
				type: Number,
				default: 5000,
			},
			message: {
				type: [String, Error],
				required: true,
			},
			type: {
				type: String,
				default: 'none',
			},
		},

		emits: ['message-hidden'],
		methods: {
			show() {
				this.$el.classList.add('fade-in');
			},

			hide() {
				this.$el.classList.add('fade-out');

				this.$el.addEventListener('animationend', () => {
					this.$emit('message-hidden');
					this.$el.remove();
				});
			},
		},

		mounted() {
			//console.error the stack to help debug instead os mask the error
			if (this.message instanceof Error) {
				console.error(this.message.stack);
			}
			this.show();

			setTimeout(() => {
				this.hide();
			}, this.duration);
		},
	};
</script>

<style></style>
