<template>
	<div
		class="modal show"
		role="dialog"
		tabindex="-1"
		:aria-labelledby="`modal-title-${id}`"
		aria-modal="true"
	>
		<div class="modal-dialog modal-md" role="document">
			<div class="modal-content">
				<div class="modal-body">
					<iframe class="login-iframe" src="/login"></iframe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { useAuthStore } from '@/stores/auth';
	export default {
		setup() {
			const authStore = useAuthStore();
			return { authStore };
		},
		data() {
			return {
				id: this._.uid,
			};
		},

		methods: {
			close() {
				this.authStore.closeLoginModal();
			},
		},

		/**
		 * Focus component when it mounts
		 */
		mounted() {
			this.$el.focus();
			window.closeLoginModal = this.close;
		},
	};
</script>

<style lang="scss">
	.login-iframe {
		min-height: 550px;
		max-height: 750px;
		height: 80vh;
		width: 100%;
		display: flex;
	}
</style>
