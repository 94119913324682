import isEmpty from 'lodash/isEmpty';
import { useClientsStore } from '@/stores/clients';

const formatSeoTitle = function (title) {
	return isEmpty(title) ? '' : title.substring(0, 70);
};

const formatSeoUrl = function (url) {
	if (isEmpty(url)) return '';
	return url
		.toString() // Convert to string
		.normalize('NFD') // Change diacritics
		.replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
		.replace(/\s+/g, '-') // Change whitespace to dashes
		.toLowerCase() // Change to lowercase
		.replace(/&/g, '-and-') // Replace ampersand
		.replace(/[^a-z0-9-]/g, '') // Remove anything that is not a letter, number or dash
		.replace(/-+/g, '-') // Remove duplicate dashes
		.replace(/^-*/, '') // Remove starting dashes
		.replace(/-*$/, '')
		.substring(0, 70); // Max length
};

const formatSeoDescription = function (description) {
	if (isEmpty(description)) return '';
	const regex = /(<([^>]+)>)|&nbsp;/gi;
	let formattedDescription = description
		.replace(regex, '')
		.replace(/&amp;/g, '&');
	return formattedDescription.substring(0, 320);
};

const getSeoUrlPrefix = function (seoUrlPath) {
	let host = useClientsStore().getSelectedStoreUrl;
	if (host.slice(0, 4) !== 'http') host = `https://${host}`;

	let pathInit = host[host.length - 1] === '/' ? '' : '/';
	if (!isEmpty(seoUrlPath)) pathInit += `${seoUrlPath}/`;

	return `${host}${pathInit}`;
};

export { formatSeoTitle, formatSeoUrl, formatSeoDescription, getSeoUrlPrefix };
