<template>
	<input
		v-bind="$attrs"
		@blur="onBlur"
		@input="onInput"
		class="form-control"
		:class="{ 'is-invalid': isInvalid || invalid }"
		:value="modelValue"
		:autocomplete="autocomplete"
	/>
</template>

<script>
	export default {
		props: {
			autocomplete: {
				type: String,
				default: 'on',
			},
			modelValue: {
				type: [String, Number],
				default: '',
			},
			invalid: {
				type: Boolean,
				default() {
					return false;
				},
			},
			// Lazy:
			// ElInput is used inside another inputs, for example ElInputPrice, and
			// some of them needs the v-model.lazy (https://vuejs.org/v2/guide/forms.html#lazy) to work.
			// However, as the v-model in them gets updated from ElInput making
			// v-model.lazy to not work properly. That's where the lazy prop will help.
			lazy: {
				type: Boolean,
				default() {
					return false;
				},
			},
			validation: {
				type: Object,
				default: undefined,
			},
			validationKey: {
				type: String,
				default: undefined,
			},
		},

		emits: ['input', 'blur', 'update:modelValue'],

		data() {
			return {
				isInvalid: false,
			};
		},

		methods: {
			onInput(event) {
				if (this.lazy) return;
				this.$emit('update:modelValue', event.target.value);
				this.$emit('input', event.target.value);
			},
			onBlur(event) {
				this.handleValidationLocal();
				this.$emit('blur', event);
			},
			handleValidationLocal() {
				let isInvalid = false;

				if (
					this.validation &&
					((this.validation[this.validationKey] &&
						this.validation[this.validationKey].$invalid) ||
						(this.$_.isEmpty(this.validationKey) &&
							this.validation.$invalid))
				) {
					isInvalid = true;
				}

				this.isInvalid = isInvalid;
			},
		},
	};
</script>

<style></style>
