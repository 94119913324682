<template>
	<div v-if="!$_.isEmpty(buttonsAction)">
		<ElButton
			@click="buttonResource('new', buttonResourceCreateCustom)"
			v-if="buttonsAction.includes('create')"
			class="btn-primary ms-2 button-action-create"
		>
			{{ $t('button.create') }} {{ resourceTitle }}
		</ElButton>
		<ElButton
			@click="productsStore.showProductsImportModal"
			v-if="buttonsAction.includes('import')"
			class="btn-primary ms-2 button-action-create"
		>
			{{ $t('products.buttons.import') }} {{ resourceTitle }}s
		</ElButton>
		<ElButton
			@click="$emit('export')"
			v-if="buttonsAction.includes('export')"
			class="btn-primary ms-2"
		>
			{{ $t('products.buttons.export') }} {{ resourceTitle }}s
		</ElButton>
	</div>
</template>

<script>
	import { useProductsStore } from '@/stores/products';
	export default {
		name: 'ViewHeaderActions',

		props: {
			buttonsAction: {
				type: Array,
				default() {
					return [];
				},
			},
			buttonsResource: {
				type: String,
				required: true,
			},
			needConfirmDeletion: {
				type: Boolean,
				default: false,
			},
		},
		emits: ['export'],

		setup() {
			const productsStore = useProductsStore();
			return { productsStore };
		},

		data: function () {
			return {
				buttonsResourceCustom:
					this.buttonsResource == 'draft_orders'
						? 'orders'
						: this.buttonsResource,
				buttonResourceCreateCustom:
					this.buttonsResource == 'orders'
						? 'draft_orders'
						: this.buttonsResource,
			};
		},

		computed: {
			resourceTitle() {
				return this.$t(this.buttonsResource + '.resourceTitle', 1);
			},
		},

		methods: {
			// categoryDeletion: CategoriesDelete.categoryDeletion(),
			buttonResource: function (typeAction, resourceType) {
				if (typeAction == 'new') {
					return this.$router.push({
						name: resourceType + '_' + typeAction,
					});
				}
			},
		},
	};
</script>
