<template>
	<a
		class="dropdown-item t-dropdown-item"
		role="menuitem"
		tabindex="-1"
		:href="href"
		@click.stop="onClick($event)"
	>
		<slot></slot>
	</a>
</template>

<script>
	export default {
		props: {
			href: {
				type: String,
				default: '#',
			},
		},

		emits: ['click'],

		methods: {
			onClick(event) {
				if (this.href === '#') event.preventDefault();
				this.$parent.hidePopper();
				this.$emit('click');
			},
		},
	};
</script>

<style></style>
