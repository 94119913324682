import { DIM, Weight } from 'lex-proto/product_pb';

export default {
	install: (app) => {
		app.config.globalProperties.$unit = function () {
			const weightUnits = () => {
				return Object.values(Weight).slice(1);
			};

			const weightUnitName = (weightUnit) => {
				let units = Object.keys(Weight);
				if (typeof units[weightUnit] == 'undefined') return weightUnit;

				return units[weightUnit].toLowerCase();
			};

			const convertWeightToOunces = (weight, fromUnit) => {
				weight = isNaN(parseFloat(weight)) ? 0 : parseFloat(weight);
				let converted = weight;

				switch (parseInt(fromUnit)) {
					case Weight.G:
						converted = weight / 28.35;
						break;
					case Weight.KGS:
						converted = weight * 35.274;
						break;
					case Weight.LBS:
						converted = weight * 16;
						break;
					case Weight.OZ:
					default:
						//no conversion needed
						break;
				}

				return converted.toFixed(1);
			};

			const convertWeightToPounds = (weight, fromUnit) => {
				weight = isNaN(parseFloat(weight)) ? 0 : parseFloat(weight);
				let converted = weight;

				switch (parseInt(fromUnit)) {
					case Weight.G:
						converted = weight / 454;
						break;
					case Weight.KGS:
						converted = weight * 2.205;
						break;
					case Weight.OZ:
						converted = weight / 16;
						break;
					case Weight.LBS:
					default:
						//no conversion needed
						break;
				}

				return converted.toFixed(1);
			};

			const dimUnits = () => {
				return Object.values(DIM).slice(1);
			};

			const dimUnitName = (dimUnit) => {
				let units = Object.keys(DIM);
				if (typeof units[dimUnit] == 'undefined') return dimUnit;

				return units[dimUnit].toLowerCase();
			};

			const convertDimToCm = (dim, fromUnit) => {
				dim = isNaN(parseFloat(dim)) ? 0 : parseFloat(dim);
				let converted = dim;
				switch (parseInt(fromUnit)) {
					case DIM.IN:
						converted = dim / 2.54;
						break;
					case DIM.M:
						converted = dim * 100;
						break;
					case DIM.CM:
					default:
						//no conversion needed
						break;
				}
				return converted.toFixed(1);
			};

			const convertDimToIn = (dim, fromUnit) => {
				dim = isNaN(parseFloat(dim)) ? 0 : parseFloat(dim);
				let converted = dim;
				switch (parseInt(fromUnit)) {
					case DIM.CM:
						converted = dim * 2.54;
						break;
					case DIM.M:
						converted = dim * 39.37;
						break;
					case DIM.IN:
					default:
						//no conversion needed
						break;
				}
				return converted.toFixed(1);
			};

			return {
				weightUnits,
				weightUnitName,
				convertWeightToOunces,
				convertWeightToPounds,
				dimUnits,
				dimUnitName,
				convertDimToCm,
				convertDimToIn,
			};
		};
	},
};
