<template>
	<div class="mb-3"><slot></slot></div>
</template>

<script>
	export default {};
</script>

<style scoped>
	fieldset > div:last-child {
		margin-bottom: 0 !important;
	}
</style>
