import { grpc } from '@improbable-eng/grpc-web';

/**
 *
 * @param {Object} message
 * @param {Function} resolve
 * @param {Function} reject
 * @param {String} returnSpecificObjectName
 * @param {Number} delayOnSuccess - time in ms | Some resources need extra time to update ElasticSearch before returning to the listing page
 */
export const onMessageResponseAsync = (
	message,
	resolve,
	reject,
	returnSpecificObjectName,
	delayOnSuccess = 0
) => {
	let response = message.toObject();
	// console.log('response ok ', JSON.parse(JSON.stringify(message.toObject())));
	if (response.success) {
		if (
			typeof returnSpecificObjectName == 'string' &&
			returnSpecificObjectName != ''
		) {
			// if want a specific data in response object
			if (typeof response[returnSpecificObjectName] != 'undefined') {
				if (delayOnSuccess > 0)
					setTimeout(
						() => resolve(response[returnSpecificObjectName]),
						delayOnSuccess
					);
				else resolve(response[returnSpecificObjectName]);
			} else {
				reject('text error to define');
			}
		} else {
			if (delayOnSuccess > 0)
				setTimeout(() => resolve(response), delayOnSuccess);
			else resolve(response);
		}
	} else if (!response.success && response.code == 1043) {
		reject({
			msg: 'A recent order was placed using this draft. Please wait 2 minutes before placing another order with the same draft. If you do like to proceed, please click on the confirmation button',
			code: response.code,
		});
	} else {
		reject(response.message);
	}
};

export const onCustomCartMessageResponseAsync = (
	message,
	resolve,
	reject,
	returnSpecificObjectName,
	delayOnSuccess = 0
) => {
	let response = message.toObject();
	// console.log('response ok ', JSON.parse(JSON.stringify(message.toObject())));
	if (response.success) {
		if (
			typeof returnSpecificObjectName == 'string' &&
			returnSpecificObjectName != ''
		) {
			// if want a specific data in response object
			if (typeof response[returnSpecificObjectName] != 'undefined') {
				if (delayOnSuccess > 0)
					setTimeout(
						() => resolve(response[returnSpecificObjectName]),
						delayOnSuccess
					);
				else resolve(response[returnSpecificObjectName]);
			} else {
				reject('text error to define');
			}
		} else {
			if (delayOnSuccess > 0)
				setTimeout(() => resolve(response), delayOnSuccess);
			else resolve(response);
		}
	} else {
		reject(response);
	}
};

export const onEndResponseAsync = (code, msg, trailers, resolve, reject) => {
	// console.log('reject', code, msg, trailers);

	if (code == grpc.Code.OK) {
		resolve(code);
	} else {
		if (code == 14) {
			reject('Service unavailable');
			return;
		}
		if (code == 7) {
			reject(
				"Permission Denied (Your user doesn't have access to this resource)"
			);
			return;
		}
		if (code == 2 && !msg) {
			reject('Connection Error');
			return;
		}
		if (code == 13) {
			reject('Error code 13 ' + msg);
			return;
		}
		if (code == 1043) {
			reject('Error code 1043 ' + msg);
			return;
		}
		// 16 not authenticated
		reject(msg);
	}
};
